import { ComponentProps } from "react";
import "./game-store-button.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectDemoCase,
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export type ButtonStoreProps = ComponentProps<"button">;

export function ButtonStore(props: ButtonStoreProps) {
  const navigate = useNavigate();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const demoCase = useAppSelector(selectDemoCase);

  return (
    <button
      {...props}
      className="button-shop-container"
      onClick={() => {
        if (demoCase && demoCase.case !== 1 && demoCase.case !== 2) {
          navigate("/store");
        }
      }}
    >
      <div className="shop-icon">
        <img src="../img/icns/shop-coin.png" alt="Shop Coins" />
      </div>
      <div className="button-text-area">
        <div className="button-title">
          {checkTranslationKey(translations[lang]?.Game_store, "Game store")}
        </div>
        <div className="button-subtitle">
          {checkTranslationKey(
            translations[lang]?.Shop_more_coins,
            "Shop more coins"
          )}
        </div>
      </div>
    </button>
  );
}

export default ButtonStore;
