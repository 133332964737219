import { ComponentProps } from "react";
import { Button } from "../../../shared/ui/button";
import "./achievement-modal.scss";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export interface AchievementProps extends ComponentProps<"div"> {
  badge: string;
  title: string;
  buttonText: string;
  children: string;
  buttonClick: () => void;
}

export function BadgeModal({
  badge,
  children,
  title,
  buttonText,
  buttonClick,
  ...props
}: AchievementProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div className="achievement-modal-container" {...props}>
      <div className="badge-header">
        <img src={badge} height="200px" alt="" />
      </div>
      <div className="badge-content">
        <div className="badge-title">{title}</div>
        <div className="badge-text">{children}</div>
        <div className="share-area">
          <Button buttonType="success" onClick={buttonClick}>
            {buttonText}
          </Button>
          <button className="share-facebook">
            <img src={`../img/icns/facebook.png`} height="24px" alt="" />
            {checkTranslationKey(
              translations[lang]?.Share_on_Facebook,
              "Share on Facebook"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BadgeModal;
