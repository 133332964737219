import "./ladder-feedback.scss";
import { Modal } from "../../modal";
import { Player } from "../../player";
import { BadgeRewards } from "../../badge-reward";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useAppSelector } from "../../../../hooks/hooks";
import { selectUser } from "../../../../auth/data-access/store/authSlice";
import {
  selectLanguage,
  selectTranslations,
} from "../../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../../utils/translation";
import { useEffect } from "react";
import SoundManager from "../../../../utils/managers/sound-manager";

export enum FeedbackType {
  Great = "great",
  Lost = "lost",
  SafePoints = "safe-points",
  Strike = "strike",
  InstantWin = "instant-win",
  Cushions = "cushions",
}

const ladderFeedbackContainerVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const rayVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const assetsVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

export interface LadderFeedbackSProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  type: FeedbackType;
  reward?: number | string;
}

export function LadderFeedback({
  show,
  setShow,
  type,
  reward,
}: LadderFeedbackSProps) {
  const user = useAppSelector(selectUser);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const ribbon = (feedbackType: string) => {
    switch (feedbackType) {
      case "great":
        return <img src="/img/icns/ribbon-wave-yellow.svg" alt="" />;
      case "lost":
        return <img src="/img/icns/ribbon-wave-red.svg" alt="" />;
      case "strike":
        return <img src="/img/icns/ribbon-wave-green.svg" alt="" />;
      case "instant-win":
        return <img src="/img/icns/ribbon-wave-green.svg" alt="" />;
      case "safe-points":
        return <img src="/img/icns/ribbon-purple.svg" alt="" />;
      case "cushions":
        return <img src="/img/icns/ribbon-yellow.svg" alt="" />;
      default:
        return <img src="/img/icns/ribbon-wave-yellow.svg" alt="" />;
    }
  };

  const message = (feedbackType: string) => {
    switch (feedbackType) {
      case "great":
        return checkTranslationKey(translations[lang]?.GREAT_JOB, "GREAT JOB!");
      case "lost":
        return checkTranslationKey(translations[lang]?.YOU_LOST, "YOU LOST!");
      case "strike":
        return checkTranslationKey(translations[lang]?.STRIKE, "STRIKE!");
      case "instant-win":
        return checkTranslationKey(
          translations[lang]?.INSTANT_WIN,
          "INSTANT WIN!"
        );
      case "safe-points":
        return checkTranslationKey(
          translations[lang]?.YOU_LOST_OR_ALMOST,
          "YOU LOST OR ALMOST!"
        );
      case "cushions":
        return checkTranslationKey(
          translations[lang]?.KEEP_CLIMBING_TO_SAFEGUARD_POINTS,
          "KEEP CLIMBING TO SAFEGUARD POINTS!"
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    if (show) {
      if (type === "safe-points") {
        SoundManager.vfxSounds.cheerful.play();
      } else if (type === "strike" || type === "instant-win") {
        SoundManager.vfxSounds.awesome.play();
      } else if (type === "great") {
        SoundManager.vfxSounds.win.play();
      } else if (type === "lost") {
        SoundManager.vfxSounds.lose.play();
      }
    }

    return () => {
      SoundManager.vfxSounds.cheerful.pause();
      SoundManager.vfxSounds.awesome.pause();
      SoundManager.vfxSounds.lose.pause();
      SoundManager.vfxSounds.well_done.pause();
    };
  }, [show]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={`ladder-message ${type}`}
          variants={ladderFeedbackContainerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Modal
            setShow={setShow}
            show={show}
            hideCloseButton
            defaultAnimations={false}
            disableClickOutside
          >
            <>
              {type === "strike" ? (
                <motion.img
                  className="strikes"
                  src="/img/lights/strikes-green.svg"
                  alt=""
                  variants={rayVariants}
                  initial={"hidden"}
                  animate={show && "visible"}
                />
              ) : null}
              <div className="message">
                {type === "great" ? (
                  <motion.img
                    className="lights"
                    src="/img/lights/lights-yellow-grouped.svg"
                    alt=""
                    variants={rayVariants}
                    initial={"hidden"}
                    animate={show && "visible"}
                  />
                ) : type === "instant-win" || type === "strike" ? (
                  <motion.img
                    className="lights"
                    src="/img/lights/lights-green-grouped.svg"
                    alt=""
                    variants={rayVariants}
                    initial={"hidden"}
                    animate={show && "visible"}
                  />
                ) : null}
                <motion.div
                  variants={assetsVariants}
                  initial={"hidden"}
                  animate={show && "visible"}
                >
                  {type !== "cushions" ? (
                    <div className="player-container">
                      <Player avatar={user?.character?.avatar || ""} />
                    </div>
                  ) : null}
                  <div className="ribbon">
                    {ribbon(type)}
                    <p>{message(type)}</p>
                  </div>
                  {type === "safe-points" ? (
                    <img
                      className="swimming-rings"
                      src="/img/icns/swimming-rings.svg"
                      alt=""
                    />
                  ) : null}
                  {reward ? (
                    <div className="reward-card">
                      <div className="badge-container">
                        <BadgeRewards />
                      </div>
                      <p className="points">{reward}</p>
                      {type === "instant-win" ? (
                        <>
                          <img
                            className="star"
                            src="/img/icns/star.svg"
                            alt=""
                          />
                          <img
                            className="star"
                            src="/img/icns/star.svg"
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="diamond"
                            src="/img/icns/diamonds.svg"
                            alt=""
                          />
                          <img
                            className="diamond"
                            src="/img/icns/diamonds.svg"
                            alt=""
                          />
                        </>
                      )}
                    </div>
                  ) : null}
                </motion.div>
              </div>
            </>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
