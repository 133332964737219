import { ComponentProps } from "react";
import "./gameplay-answer.scss";
import {
  GameplayAnswerImageText,
  GameplayAnswerImageTextProps,
} from "./image-with-text";
import {
  GameplayAnswerImage,
  GameplayAnswerImageProps,
} from "./image/gameplay-image";
import { GameplayAnswerText, GameplayAnswerTextProps } from "./text";

type ButtonType = Omit<ComponentProps<"button">, "children">;
export type GameplayAnswerPlayer = "user" | "opponent" | "both";
export type GameplayAnswerStatus = "default" | "correct" | "wrong";

export type CommonAnswersType = ButtonType & {
  status: GameplayAnswerStatus;
  player?: GameplayAnswerPlayer;
};

export type GameplayAnswerProps =
  | GameplayAnswerImageProps
  | GameplayAnswerTextProps
  | GameplayAnswerImageTextProps;

export function GameplayAnswer(props: GameplayAnswerProps) {
  switch (props.gameplay) {
    case "image":
      return <GameplayAnswerImage {...props} />;

    case "text":
      return <GameplayAnswerText {...props} />;

    case "image-with-text":
      return <GameplayAnswerImageText {...props} />;

    default:
      return <p>Not yet implemented</p>;
  }
}

export default GameplayAnswer;
