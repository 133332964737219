import React from "react";

import Highlight from "../highlight/highlight";

import "./button-close.scss";

type ButtonCloseProps = {
  onClick: () => void;
};

const ButtonClose = ({ onClick }: ButtonCloseProps) => {
  return (
    <div onClick={onClick} className="button-close">
      <Highlight>
        <img src="/img/icns/close.png" alt="Close button" width="20" />
      </Highlight>
    </div>
  );
};

export default ButtonClose;
