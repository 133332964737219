import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Background } from "../../../shared/ui/background";
import { Modal } from "../../../shared/ui/modal";
import { NavBar } from "../../../shared/ui/navbar";
import { BonusResultModal } from "../../ui/bonus-result";
import { GameplayLayout } from "../../ui/gameplay-layout";
import { PrizeQuestion } from "../../ui/question-prize";
import "./gameplay-bonus.scss";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Player, PlayerProps } from "../../../shared/ui/player";
import { bonusResultContainerVariants } from "../../utils/animation-states";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGameEvents } from "../../../shared/data-access/store/game/hooks/use-game-events";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../../auth/data-access/store/authSlice";
import {
  selectDemoCase,
  selectLanguage,
} from "../../../shared/data-access/store/general/generalSlice";
import { GameplayAnswer, GameplayAnswerPlayer } from "../../ui/gameplay-answer";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { useCountdown } from "../../../utils/hooks/use-countdown";
import { Badge } from "../../../shared/ui/badge";
import LoadingOpponent from "../../../shared/ui/loading-opponent/loading-opponent";
import { reset } from "../../../shared/data-access/store/game/gameSlices";
import {
  SocketResponse,
  SocketResponseError,
} from "../../../shared/data-access/store/game/types/requests";
import { InitBonusGameEvent } from "../../../shared/data-access/store/game/types/socket-events/init-bonus-game";
import { StartBonusGameEvent } from "../../../shared/data-access/store/game/types/socket-events/start-bonus-game";
import { BonusOpponentEvent } from "../../../shared/data-access/store/game/types/socket-events/get-bonus-opponent";
import { FinishBonusGameEvent } from "../../../shared/data-access/store/game/types/socket-events/finish-bonus-game";
import Highlight from "../../../shared/ui/highlight/highlight";
import { differenceInMilliseconds } from "date-fns";
import SoundManager from "../../../utils/managers/sound-manager";

const initBonusGameMock = {
  id: "6346dce39786c8db9b0ac59e",
  bonus_game: {
    id: "634030553922dc23210422b9",
    type: "fastest_finger",
    title: {
      en: "Fastest Finger",
      fr: "Fastest Finger",
      ar: "Fastest Finger",
    },
    description: {
      en: "Mystery challenge!",
      fr: "Mystery challenge!",
      ar: "Mystery challenge!",
    },
    hint: {
      en: "Answer faster than your opponent to win extra diamonds & lives!",
      fr: "Answer faster than your opponent to win extra diamonds & lives!",
      ar: "Answer faster than your opponent to win extra diamonds & lives!",
    },
  },
};

const startBonusGameMock = {
  id: "6346dce39786c8db9b0ac59e",
  question_category: {
    id: "634030553922dc2321042296",
    code: "general_knowledge",
    name: {
      en: "General knowledge",
      fr: "General knowledge",
      ar: "General knowledge",
    },
    description: {
      en: "General knowledge",
      fr: "General knowledge",
      ar: "General knowledge",
    },
    icon: "https://api.battleup.dev1.beecoded.ro/storage/question_categories/generalknowledge.png",
    color: "#56D598",
    gradient_background: "linear-gradient(180deg, #56D598 0%, #27AA6B 100%)",
    gradient_circle:
      "linear-gradient(0, #0D864C 10.35%, #56D598 45.59%, #A8F8D1 73.09%, #56D598 84.21%)",
  },
  question: {
    id: "634030553922dc23210422d9",
    category_id: "634030553922dc2321042296",
    type_id: "634030553922dc2321042295",
    content: {
      en: "Which one of the following names is first in alphabetical order?",
      fr: "Which one of the following names is first in alphabetical order?",
      ar: "Which one of the following names is first in alphabetical order?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "Thomas",
          fr: "Thomas",
          ar: "Thomas",
        },
      },
      {
        type: "text",
        value: {
          en: "Timothi",
          fr: "Timothi",
          ar: "Timothi",
        },
      },
      {
        type: "text",
        value: {
          en: "Theodore",
          fr: "Theodore",
          ar: "Theodore",
        },
      },
      {
        type: "text",
        value: {
          en: "Ted",
          fr: "Ted",
          ar: "Ted",
        },
      },
    ],
    timer: 10,
  },
};

const bonusOpponentMock = {
  id: "6346dce39786c8db9b0ac59e",
  opponent: {
    id: "6345253285b9da70e5093773",
    nickname: "User65402834",
    avatar:
      "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Female-09.png",
    level: 11,
  },
};

const finishGameMock = {
  id: "6346dce39786c8db9b0ac59e",
  answer: {
    answer_index: 2,
    answer_time: 11014, // not used in demo, see playerAnswerTime instead
    correct: true,
    correct_answer_index: 2,
  },
  opponent_answer: {
    answer_index: 0,
    answer_time: 8250,
    correct: false,
  },
  won: true,
  prize_type: null,
  prize: null,
  prizes: {
    prize: null,
    points: 50,
    lives: 1,
  },
};

export function BonusGameplay() {
  const demoCase = useAppSelector(selectDemoCase);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id");
  const { meta } = useGameEvents("init_bonus_game");
  const game:
    | SocketResponse<"init_bonus_game", InitBonusGameEvent>
    // @ts-ignore
    | undefined = { call: "init_bonus_game", data: initBonusGameMock };

  const opponentData:
    | SocketResponse<"get_bonus_opponent", BonusOpponentEvent>
    | undefined = {
    call: "get_bonus_opponent",
    data: bonusOpponentMock,
  };
  const startGameData:
    | SocketResponse<"start_bonus_game", StartBonusGameEvent>
    // @ts-ignore
    | undefined = { call: "start_bonus_game", data: startBonusGameMock };
  const gameResults:
    | SocketResponse<"finish_bonus_game", FinishBonusGameEvent>
    | undefined = {
    call: "finish_bonus_game",
    // @ts-ignore
    data: finishGameMock,
  };

  const results = gameResults?.data;

  const playerAnswer = results?.answer;
  const opponentAnswer = results?.opponent_answer;

  const lang = useAppSelector(selectLanguage);

  const { question } = startGameData?.data || {};
  const user = useAppSelector(selectUser);
  const opponent = opponentData?.data?.opponent;

  const timer = question?.timer;

  const { timeLeft, start, stop, getUntilDateTime, clean } =
    useCountdown(timer);

  const { token, uuid } = meta;
  const currentTimeRef = useRef<number>();
  const answeredRef = useRef(false);
  const opponentRef = useRef(opponent);
  const [opponentAnswered, setOpponentAnswered] = useState(false);
  const [playerAnswerTime, setPlayerAnswerTime] = useState(0);

  const correctAnswer = useMemo(
    () =>
      answeredRef.current
        ? {
            correct_answer_index: playerAnswer?.correct_answer_index,
            answer_index: playerAnswer?.answer_index,
          }
        : false,
    [playerAnswer, answeredRef.current]
  );

  const formatAnswerTime = (ms: number) => {
    return (ms / 1000).toFixed(2);
  };

  const commonParams = useMemo(() => {
    if (!id) {
      return false;
    }
    return {
      playedBonusGame: {
        id,
      },
      userToken: token,
      uuid,
    };
  }, [id, token, uuid]);

  const handleError = (error: unknown) => {
    const message =
      (error as Error).message ??
      (error as { error: Error }).error.message ??
      (error as { error: { error: string } }).error.error ??
      error ??
      "Unknown error";
    if ((error as SocketResponseError).error_header === 400) {
      dispatch(
        setMessage({
          content: message.includes("synchroniz")
            ? "Oops, this game has expired"
            : "Oops, something went wrong",
          type: "error",
        })
      );
    } else {
      dispatch(
        setMessage({
          content: message,
          type: "error",
        })
      );
    }

    setTimeout(() => {
      dispatch(reset());
      navigate("/");
    }, 3000);
  };

  const getAnswerType = (index: number): GameplayAnswerPlayer | undefined => {
    const playerAnswerIndex = results?.answer.answer_index;
    const opponentAnswerIndex = results?.opponent_answer.answer_index;
    if (
      playerAnswerIndex === index &&
      playerAnswerIndex === opponentAnswerIndex &&
      opponentAnswered
    ) {
      return "both";
    }

    if (playerAnswerIndex === index) {
      return "user";
    }

    if (opponentAnswerIndex === index && opponentAnswered) {
      return "opponent";
    }
  };

  const handleAnswer = useCallback(
    async () => {
      const untilDateTime = getUntilDateTime();
      if (
        !commonParams ||
        !untilDateTime ||
        !timer ||
        currentTimeRef.current === undefined ||
        answeredRef.current
      ) {
        return;
      }

      setPlayerAnswerTime(
        Math.min(
          startBonusGameMock.question.timer * 1000 -
            differenceInMilliseconds(untilDateTime, new Date()),
          startBonusGameMock.question.timer * 1000
        )
      );
      answeredRef.current = true;
      clean();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commonParams, getUntilDateTime, timer]
  );

  const isImageQuestion = () =>
    question && question.answers[0].type === "image";

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    opponentRef.current = opponent;
  }, [opponent]);

  useEffect(() => {
    if (!results || !playerAnswerTime) {
      return;
    }

    const playerTime = playerAnswerTime;
    const opponentTime = results.opponent_answer.answer_time;
    const showReward = () => {
      setTimeout(() => {
        setShowInfo(true);
      }, 4000);
    };

    console.log({ playerTime, opponentTime });
    if (playerTime < opponentTime) {
      const timeout = setTimeout(() => {
        setOpponentAnswered(true);
        stop();
        showReward();
      }, opponentTime - playerTime);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      setOpponentAnswered(true);
      stop();
      showReward();
    }
  }, [results, playerAnswerTime]);

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    if (timeLeft < 5) {
      SoundManager.vfxSounds.clock.playbackRate = 1.3;
    }

    if (timeLeft > 0) {
      currentTimeRef.current = timeLeft;
      SoundManager.vfxSounds.clock.play();
      if (timeLeft === 2) {
        stop();
        handleAnswer();
      }
    } else {
      if (timeLeft === 0) {
        if (currentTimeRef.current && currentTimeRef.current > 0) {
          currentTimeRef.current = 0;
        }
      } else {
        if (!currentTimeRef.current) {
          currentTimeRef.current = 0;
        }
        stop();
        handleAnswer();
      }
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!opponent?.id) {
      return;
    }

    (async () => {
      try {
        if (commonParams) {
          start();
        }
      } catch (error) {
        handleError(error);
      }
    })();
  }, [opponent?.id]);

  useEffect(() => {
    if (showInfo && results) {
      if (results.won) {
        SoundManager.vfxSounds.win.play();
      } else {
        SoundManager.vfxSounds.lose.play();
      }
    }
  }, [showInfo, results]);

  useEffect(() => {
    if (answeredRef.current) {
      SoundManager.vfxSounds.clock.pause();
      SoundManager.vfxSounds.clock.playbackRate = 1;
      SoundManager.vfxSounds.clock.currentTime = 0;
    }
  }, [answeredRef.current]);

  const handleContinue = useCallback((showModal: boolean) => {
    // @ts-ignore
    window.location.href = window.location.origin;
    // navigate("/");
    dispatch(reset());
    setShowInfo(showModal);
  }, []);

  const generatePrizeDescription = (
    prizes: Array<{ type: string; value: number | string }>
  ) => {
    const filteredPrizes = prizes.filter((prize) => prize.value);
    let text = "";
    filteredPrizes.forEach((prize, index) => {
      console.log((index > 0 ? "&" : "") + prize.value + " " + prize.type);
      text += (index > 0 ? " & " : "") + prize.value + " " + prize.type;
    });
    return text;
  };

  return (
    <div className="gameplay-page">
      <Background
        type={
          startGameData && startGameData.data?.question_category?.code
            ? startGameData.data.question_category?.code
            : "defaultGame"
        }
        category={startGameData?.data?.question_category?.gradient_background}
      >
        <NavBar hideMenu slotEnd="lives" />
        <GameplayLayout
          timer={currentTimeRef.current}
          prizes={
            <PrizeQuestion
              icon="diamonds"
              category={startGameData?.data?.question_category?.icon || ""}
            >
              {100}
            </PrizeQuestion>
          }
          question={
            question?.content[lang] ??
            (question?.content.en || "Loading question...")
          }
          players={[
            <Player
              key="me"
              avatar={user?.character.avatar}
              playerType={
                results && answeredRef.current
                  ? (cx({
                      success: results.answer.correct,
                      defeat: !results.answer.correct,
                    }) as PlayerProps["playerType"])
                  : "primary"
              }
              left={
                results && answeredRef.current ? (
                  <img
                    src={`../img/icns/answer-${
                      results.answer.correct ? "correct" : "wrong"
                    }.svg`}
                    className="icon-input"
                    alt=""
                  />
                ) : undefined
              }
              right={
                results && answeredRef.current ? (
                  <Badge
                    type={results.answer.correct ? "success" : "danger"}
                    aspect="rectangle"
                  >
                    {timer && formatAnswerTime(playerAnswerTime)}
                  </Badge>
                ) : undefined
              }
            />,
            opponent ? (
              <Player
                key="opponent"
                avatar={opponent?.avatar}
                playerType={
                  results && opponentAnswered
                    ? (cx({
                        success: results.opponent_answer.correct,
                        defeat: !results.opponent_answer.correct,
                      }) as PlayerProps["playerType"])
                    : "primary"
                }
                left={
                  results && opponentAnswered ? (
                    <Badge
                      type={
                        results.opponent_answer.correct ? "success" : "danger"
                      }
                      aspect="rectangle"
                    >
                      {formatAnswerTime(results.opponent_answer.answer_time)}
                    </Badge>
                  ) : undefined
                }
                right={
                  results && opponentAnswered ? (
                    <img
                      src={`../img/icns/answer-${
                        results.opponent_answer.correct ? "correct" : "wrong"
                      }.svg`}
                      className="icon-input"
                      alt=""
                    />
                  ) : undefined
                }
              />
            ) : (
              <div className="player-container">
                <LoadingOpponent />
              </div>
            ),
          ]}
        >
          {/* If the options are type image add class .options-images on the .options div */}
          <div
            className={cx("options", {
              "options-images": isImageQuestion(),
            })}
          >
            {question?.answers?.map((answer, index) => {
              const key = `${answer.type}-${index}`;
              const status = correctAnswer
                ? (cx({
                    correct: correctAnswer.correct_answer_index === index,
                    wrong:
                      correctAnswer.correct_answer_index !== index &&
                      (playerAnswer?.answer_index === index ||
                        (opponentAnswer?.answer_index === index &&
                          opponentAnswered)),
                  }) as "correct" | "wrong") ?? "default"
                : "default";
              if (answer.type === "text") {
                const answerTemplate = (
                  <GameplayAnswer
                    key={key}
                    gameplay="text"
                    status={status}
                    player={getAnswerType(index)}
                    options={{
                      text: answer.value[lang] || answer.value.en,
                      badgeType: "outer",
                    }}
                    onClick={() => {
                      if (demoCase && demoCase.step === 6) {
                        return null;
                      } else {
                        handleAnswer();
                      }
                    }}
                    disabled={!!correctAnswer}
                  />
                );

                if (
                  demoCase &&
                  demoCase.case === 1 &&
                  demoCase.step === 14 &&
                  index === 2
                ) {
                  return (
                    <Highlight
                      className={"highlight-answer"}
                      key={`answer-${index}`}
                    >
                      {answerTemplate}
                    </Highlight>
                  );
                }

                return answerTemplate;
              } else if (answer.type === "image") {
                return (
                  <GameplayAnswer
                    key={key}
                    gameplay="image"
                    player={getAnswerType(index)}
                    status={status}
                    options={{ imageSrc: answer.value }}
                    onClick={() => handleAnswer()}
                    disabled={!!correctAnswer}
                  />
                );
              }
            })}
          </div>
        </GameplayLayout>
      </Background>
      <AnimatePresence>
        {showInfo && results && (
          <motion.div
            variants={bonusResultContainerVariants}
            initial={"hidden"}
            animate={"visible"}
            exit={"hidden"}
            className="modal-presence-wrapper"
          >
            <Modal
              show={showInfo}
              setShow={handleContinue}
              hideCloseButton
              defaultAnimations={false}
              disableClickOutside
            >
              {/* @TODO: @VladS, Adrian please fix the modal so it shows and redirects properly */}
              <BonusResultModal
                bonusCategory="music"
                subtitle={game?.data?.bonus_game?.title[lang] || ""}
                title={results.won ? "You won" : "You Lost"}
                awardQuantityLives={results.prizes?.lives || 0}
                awardQuantityPoints={results.prizes?.points || 0}
                prizeImage={results.prizes?.prize?.image}
                prizeText={results.prizes?.prize?.name[lang]}
                text={
                  results.won ? `You have just won` : "Move faster next time"
                }
                boldText={
                  results.won
                    ? generatePrizeDescription([
                        { type: "lives", value: results?.prizes?.lives },
                        { type: "diamonds", value: results?.prizes?.points },
                        { type: "", value: results?.prizes?.prize?.name[lang] },
                      ])
                    : ""
                }
                buttonText={results.won ? "Collect" : "Continue"}
                iconSlotLeft={
                  results.prizes.lives
                    ? "lives"
                    : results.prizes.points
                    ? "diamonds"
                    : undefined
                }
                iconSlotRight={
                  results.prizes.points
                    ? "diamonds"
                    : results.prizes.lives
                    ? "lives"
                    : undefined
                }
                mainIcon={results.won ? "ribon" : "lost"}
                onClick={() => handleContinue(false)}
              />
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default BonusGameplay;
