import { Navigate, Route, Routes } from "react-router-dom";
import { GameplayGuessIt } from "../gameplay-guess-it";
import { BonusGameplay } from "../gameplay-bonus/gameplay-bonus";

export function GameplayShell() {
  return (
    <Routes>
      <Route path="/classic" element={<GameplayGuessIt />} />
      <Route path="/bonus" element={<BonusGameplay />} />
      <Route path="*" element={<Navigate to="./classic" />} />
    </Routes>
  );
}

export default GameplayShell;
