import { ComponentProps, useState } from "react";
import { TextUnderscore } from "../../../shared/ui/text-underscore";
import "./gameplay-layout.scss";
import { motion, Variants } from "framer-motion";

export interface GameplayLayoutProps extends ComponentProps<"div"> {
  prizes: Array<JSX.Element> | JSX.Element | string;
  question: string;
  hint?: Array<JSX.Element> | JSX.Element | string;
  children: Array<JSX.Element> | JSX.Element | string;
  timer?: number;
  players?: Array<Array<JSX.Element> | JSX.Element | string>;
}

const questionVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const answersVariants: Variants = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const hintsVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};

export function GameplayLayout({
  prizes,
  question,
  hint,
  children,
  timer = -1,
  players = [],
  ...props
}: GameplayLayoutProps) {
  const [timerVariants] = useState<Variants>({
    shake: () => ({
      rotate: [0, 12, -12, 0],
      transition: {
        duration: 0.3,
        ease: "linear",
        times: [0, 0.33, 0.66, 1],
        repeat: Infinity,
        repeatDelay: 5,
        delay: 5,
      },
    }),
    shakeFaster: () => ({
      rotate: [0, 12, -12, 0],
      transition: {
        duration: 0.3,
        ease: "linear",
        times: [0, 0.33, 0.66, 1],
        repeat: Infinity,
        repeatDelay: 0,
        delay: 1,
      },
    }),
    disappear: () => ({
      opacity: 0,
    }),
  });

  return (
    <div className="gameplay-container" {...props}>
      <div className="gameplay-content">
        <div className="gameplay-area">
          <motion.div
            className="timer timer-position"
            variants={timerVariants}
            animate={timer === -1 ? "disappear" : ""}
          >
            <motion.img
              src="/img/icns/time.png"
              className="time-img"
              alt="time-sand"
              variants={timerVariants}
              animate={timer < 6 ? "shakeFaster" : "shake"}
            />
            <span className="timer-text">{timer}</span>
          </motion.div>
          <motion.div
            className="question-area"
            variants={questionVariants}
            initial="hidden"
            animate="visible"
          >
            {prizes}
            <div className="question">
              <TextUnderscore>{question}</TextUnderscore>
            </div>
          </motion.div>
          <motion.div
            className="answer-area"
            variants={answersVariants}
            initial="hidden"
            animate="visible"
          >
            {children}
          </motion.div>
        </div>
        {/* If is Bonus Game: show .players-area and hide .hint-area */}
        <motion.div
          className="hint-area"
          variants={hintsVariants}
          initial="hidden"
          animate="visible"
        >
          {hint}
        </motion.div>
        <div className="players-area">
          {players.filter(Boolean).map((player, index) => (
            <div key={index} className="player-container">
              {player}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GameplayLayout;
