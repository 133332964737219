import { Modal } from "../../modal";
import "./ladder-cushion-safe.scss";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../../utils/translation";
import { useEffect } from "react";
import SoundManager from "../../../../utils/managers/sound-manager";

const ladderCushionContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1.5,
    },
  },
};

const badgeVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: [1, 1.1, 1],
    transition: {
      duration: 0.2,
      delay: 0.8,
      scale: {
        repeat: 1,
      },
    },
  },
};

export interface LadderFeedbackSProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  points: number | string;
}

export function LadderCushionSafe({
  show,
  setShow,
  points,
}: LadderFeedbackSProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  useEffect(() => {
    if (show) {
      SoundManager.vfxSounds.angelic.play();
    }

    return () => {
      SoundManager.vfxSounds.angelic.pause();
    };
  }, [show]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="ladder-cushion-safe"
          variants={ladderCushionContainerVariants}
          initial={"hidden"}
          animate={"visible"}
          exit={"hidden"}
        >
          <Modal
            setShow={setShow}
            show={show}
            defaultAnimations={false}
            hideCloseButton
            disableClickOutside
          >
            <motion.div
              className="ladder-cushion-safe-bg"
              variants={bgVariants}
              initial={"hidden"}
              animate={show && "visible"}
            >
              <motion.img
                className="cushion"
                src="/img/icns/cushion.svg"
                alt=""
                variants={badgeVariants}
                initial={"hidden"}
                animate={show && "visible"}
              />
              <motion.div
                className="message"
                variants={contentVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <p className="points">
                  <img src="/img/icns/diamond-flipped.svg" alt="" />
                  <span>{points}</span>
                </p>
                <p className="description">
                  {checkTranslationKey(
                    translations[lang]?.YOUR_POINTS_HAVE_BEEN_SAFEGUARDERD,
                    "YOUR POINTS HAVE BEEN SAFEGUARDERD!"
                  )}
                </p>
              </motion.div>
            </motion.div>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
