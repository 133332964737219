// import { motion, Variants } from "framer-motion";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import "./upstream-service-banner.scss";
import { checkTranslationKey } from "../../../utils/translation";

// const bannerVariants: Variants = {
//   hidden: {
//     y: "100%",
//   },
//   visible: {
//     y: 0,
//     transition: {
//       duration: 0.3,
//       ease: "easeOut",
//     },
//   },
// };

export function UpstreamServiceBanner() {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div
      id="banner-upstream"
      // variants={bannerVariants}
      // initial="hidden"
      // animate="visible"
    >
      <div className="banner-area">
        <div className="flex-container">
          {/*<img src="/img/upstream-logo.png" alt="" />*/}
          <div className="service-text">
            {checkTranslationKey(
              translations[lang]?.The_service_is_only_available,
              "The service is only available for subscribers of the specific operator"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpstreamServiceBanner;
