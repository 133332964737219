import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  setIsLoadingPage,
  setModal,
} from "../../shared/data-access/store/ui/uiSlice";
import { Background } from "../../shared/ui/background";
import ButtonClose from "../../shared/ui/button-close/button-close";
import { checkTranslationKey } from "../../utils/translation";
import "./menu.scss";
import {
  selectLanguage,
  selectTranslations,
} from "../../shared/data-access/store/general/generalSlice";
import { gameRoutes } from "../../App";
import useLocals from "../../utils/hooks/use-locals";
import { useGameEvents } from "../../shared/data-access/store/game/hooks/use-game-events";
import { useMemo } from "react";
import { reset } from "../../shared/data-access/store/game/gameSlices";
import Highlight from "../../shared/ui/highlight/highlight";

export function Menu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const pathname = useLocation().pathname;
  const { send: finishGame, game, meta } = useGameEvents("finish_game");
  const { token, uuid } = meta;
  const { reset: resetLocals } = useLocals();

  const commonParams = useMemo(() => {
    if (!game?.id) {
      return false;
    }
    return {
      playedGame: {
        id: game.id,
      },
      userToken: token,
      uuid,
    };
  }, [game?.id, token, uuid]);

  const navigationGuard = async (callback: () => void) => {
    if (gameRoutes.some((keyword) => pathname.includes(keyword))) {
      if (!commonParams) {
        return;
      }
      if (confirm("Are you sure you want to end this game?")) {
        resetLocals();
        dispatch(setIsLoadingPage(true));
        await finishGame({ ...commonParams, force_finish: true });
        dispatch(reset());
        dispatch(setIsLoadingPage(false));
        callback();
      }
    } else {
      callback();
    }
  };

  const handleMenuClick = (route = "/") => {
    navigationGuard(() => {
      dispatch(setModal({ modal: "menu", value: false }));
      setTimeout(() => {
        navigate(route);
      }, 300);
    });
  };

  return (
    <Background type="onboarding">
      <div id="menu">
        <div className="menu-content">
          <div className="header-transparent">
            <img
              className="logo-menu"
              src="/img/batteup-logo-2x.png"
              alt={checkTranslationKey(
                translations[lang]?.BattleUp_Logo,
                "BattleUp Logo"
              )}
            />
            <ButtonClose
              onClick={() =>
                dispatch(setModal({ modal: "menu", value: false }))
              }
            />
          </div>
          <div className="menu-list">
            <ul>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/home")}>
                <Highlight>
                  {checkTranslationKey(translations[lang]?.Home, "Home")}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/prizes")}>
                <Highlight>
                  {checkTranslationKey(translations[lang]?.Prizes, "Prizes")}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/store")}>
                <Highlight>
                  {checkTranslationKey(translations[lang]?.Store, "Store")}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/profile")}>
                <Highlight>
                  {checkTranslationKey(translations[lang]?.Profile, "Profile")}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/leaderboard")}>
                <Highlight>
                  {checkTranslationKey(translations[lang]?.Ranking, "Ranking")}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/onboarding")}>
                <Highlight>
                  {checkTranslationKey(
                    translations[lang]?.Tutorial,
                    "Tutorial"
                  )}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/faq")}>
                <Highlight>
                  {checkTranslationKey(translations[lang]?.F_A_Q, "F.A.Q")}
                </Highlight>
              </li>
              <div className="list-ellipse"></div>
              <li>
                {checkTranslationKey(translations[lang]?.Logout, "Logout")}
              </li>
              <div className="list-ellipse"></div>
            </ul>
          </div>
          <p className="menu-terms">
            {checkTranslationKey(
              translations[lang]?.Terms_and_Conditions,
              "Terms and Conditions"
            )}
          </p>
        </div>
      </div>
    </Background>
  );
}

export default Menu;
