import { CoinOfferButton } from "../../../shared/ui/coin-offer";
import "./store-coins.scss";
import { CreditPackagesResponse } from "../../../shared/interfaces/general.types";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { checkTranslationKey } from "../../../utils/translation";

const storeContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const storeIconVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};

const creditPackageVariants: Variants = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.3 + 0.2 * (custom + 1),
    },
  }),
};

type CoinsPageProps = {
  title?: string;
  subtitle?: string;
  confirmCallback?: () => void;
};

export function CoinsPage({ title, subtitle }: CoinsPageProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const creditPackagesData = {
    items: [
      {
        id: "634030553922dc23210422b0",
        name: "1 ticket",
        price: 1,
        currency: "€",
        quantity: 1,
        updated_at: "2022-10-07T13:57:41.751000Z",
        created_at: "2022-10-07T13:57:41.751000Z",
      },
      {
        id: "634030553922dc23210422b1",
        name: "3 tickets",
        price: 2.8,
        currency: "€",
        quantity: 3,
        updated_at: "2022-10-07T13:57:41.758000Z",
        created_at: "2022-10-07T13:57:41.758000Z",
      },
      {
        id: "634030553922dc23210422b2",
        name: "6 tickets",
        price: 5,
        currency: "€",
        quantity: 6,
        heading: {
          text: {
            en: "POPULAR",
          },
          template: 1,
        },
        updated_at: "2022-10-07T13:57:41.759000Z",
        created_at: "2022-10-07T13:57:41.759000Z",
      },
      {
        id: "634030553922dc23210422b3",
        name: "12 tickets",
        price: 12,
        currency: "€",
        quantity: 9,
        heading: {
          text: {
            en: "BEST VALUE",
          },
          template: 2,
        },
        updated_at: "2022-10-07T13:57:41.761000Z",
        created_at: "2022-10-07T13:57:41.761000Z",
      },
    ],
  };

  const templateColors: Array<"orange" | "purple" | "blue"> = [
    "orange",
    "purple",
    "blue",
  ];

  const renderCreditPackages = (
    creditPackages: CreditPackagesResponse["items"]
  ) => {
    return creditPackages?.map((creditPackage, index) => {
      return (
        <motion.div
          key={creditPackage.id}
          variants={creditPackageVariants}
          initial="hidden"
          animate="visible"
          custom={index}
          style={{ width: "100%" }}
        >
          <CoinOfferButton
            className="store-offer"
            offerType={templateColors[index]}
            price={creditPackage.currency + creditPackage.price}
            coins={creditPackage.quantity}
            heading={
              creditPackage.heading
                ? creditPackage.heading?.text[lang]
                : undefined
            }
          ></CoinOfferButton>
        </motion.div>
      );
    });
  };

  return (
    <>
      <AnimatePresence>
        {creditPackagesData && (
          <motion.div
            className="card-container"
            variants={storeContainerVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="icon-position">
              <motion.img
                src="../img/icns/shop-coin.png"
                alt=""
                width={"50px"}
                variants={storeIconVariants}
                initial="hidden"
                animate="visible"
              />
            </div>
            <div className="card-content">
              <div className="text">
                <h3>
                  {title ||
                    checkTranslationKey(
                      translations[lang]?.Buy_more_coins_complete_more,
                      "Buy more coins, complete more challenges and get a head start in the leaderboard."
                    )}
                </h3>
                {subtitle && <p>{subtitle}</p>}
              </div>
              <div className="store-offers">
                {/* @ts-ignore */}
                {renderCreditPackages(creditPackagesData.items)}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default CoinsPage;
