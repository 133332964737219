import { ButtonStore } from "../../shared/ui/game-store-button";
import { Avatar } from "../../shared/ui/avatar";
import { Background } from "../../shared/ui/background";
import { Button } from "../../shared/ui/button";
import { NavBar } from "../../shared/ui/navbar";
import { PointsArea } from "../../shared/ui/points-area";
import { PrizeToWin } from "../../shared/ui/prize-to-win";
import { UpstreamServiceBanner } from "../../shared/ui/upstream-service-banner";

import "./home.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { selectUser } from "../../auth/data-access/store/authSlice";
import { useNavigate } from "react-router-dom";
import {
  selectDemoCase,
  selectInit,
  selectLanguage,
  selectTranslations,
  updateDemoCase,
} from "../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../utils/translation";
import { motion, Variants } from "framer-motion";
import { reset } from "../../shared/data-access/store/game/gameSlices";
import useLocals from "../../utils/hooks/use-locals";
import Highlight from "../../shared/ui/highlight/highlight";
import useAmbientSound from "../../utils/hooks/use-ambient-sound";
import SoundManager from "../../utils/managers/sound-manager";

const contentVariants: Variants = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  },
};

const buttonVariants: Variants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const prizesVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2 * custom,
    },
  }),
};

export const checkEligibleToPlay = ({
  eligible,
  canPlay,
  disableBuyCredits,
  texts,
}: {
  eligible: boolean;
  canPlay: boolean;
  disableBuyCredits: boolean;
  texts: {
    btnPlay: string;
    btnPlayBuy: string | JSX.Element;
    descriptionFinishedAll: string;
    descriptionBuy: string;
  };
}): {
  disabled: boolean;
  textButton: string | JSX.Element;
  description: string;
  buyCredits: boolean;
} => {
  let disabled = false;
  let textButton: string | JSX.Element = texts.btnPlay;
  let description = "";
  let buyCredits = false;

  if (eligible && canPlay) {
    disabled = false;
    textButton = texts.btnPlay;
    description = "";
  } else if (eligible && !canPlay && disableBuyCredits) {
    disabled = true;
    textButton = texts.btnPlay;
    description = texts.descriptionFinishedAll;
  } else if (!eligible && !canPlay) {
    disabled = true;
    textButton = texts.btnPlay;
    description = texts.descriptionBuy;
  } else if (eligible && !canPlay && !disableBuyCredits) {
    disabled = false;
    textButton = texts.btnPlayBuy;
    description = "";
    buyCredits = true;
  }

  return {
    disabled,
    textButton,
    description,
    buyCredits,
  };
};

export function Home() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const { setValue } = useLocals();
  const demoCase = useAppSelector(selectDemoCase);
  useAmbientSound("home");

  const handlePlay = () => {
    dispatch(reset());
    setValue("triggerNewGame", "yes");
    if (demoCase) {
      if (demoCase.case === 1 && demoCase.step === 2) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 3 }));
      } else if (demoCase.case === 2 && demoCase.step === 1) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 2 }));
      }
      navigate("/opponent");
      SoundManager.vfxSounds.play.play();
    }
  };

  const handleBuyCredits = () => {
    navigate("/store");
  };

  const { disabled, textButton, description, buyCredits } = checkEligibleToPlay(
    {
      eligible: user?.eligibility?.eligible || false,
      canPlay: user?.eligibility?.canPlay || false,
      disableBuyCredits: init?.features?.disableBuyCredits || false,
      texts: {
        btnPlay: checkTranslationKey(translations[lang]?.Play_now, "Play now!"),
        btnPlayBuy: (
          <>
            {checkTranslationKey(
              translations[lang]?.Play_again_for,
              "Play again for"
            )}
            <img src="/img/icns/coin.svg" alt="" />
          </>
        ),
        descriptionFinishedAll: checkTranslationKey(
          translations[lang]?.You_played_all_the_games_of_the_day,
          "You played all the games of the day! See you again tomorrow!"
        ),
        descriptionBuy: checkTranslationKey(
          translations[lang]?.You_cannot_play_right_now_Check_your_balance,
          "You cannot play right now. Check your balance and try again."
        ),
      },
    }
  );

  return (
    <div id="home">
      <Background type="home">
        <NavBar
        // slotEnd={
        //   <NavbarScore
        //     scoreType="primary"
        //     score={22}
        //     units="Level"
        //     icon={"level"}
        //   ></NavbarScore>
        // }
        ></NavBar>

        <div className="home-content">
          <div className="content-area">
            <motion.div
              className="points-area"
              variants={contentVariants}
              initial="hidden"
              animate="visible"
            >
              <div className="user-points">
                <div className="user">
                  <Avatar
                    image={user?.character?.avatar || ""}
                    className="user-image"
                  ></Avatar>
                  <div className="user-name">{user?.character?.nickname}</div>
                  <div className="user-edit">
                    <div className="edit-button">
                      <img src="/img/icns/pencil-fill.svg" alt="edit" />
                    </div>
                  </div>
                </div>
                <div className="points">
                  <PointsArea
                    count={user?.character?.points}
                    icon="diamonds"
                    label={checkTranslationKey(
                      translations[lang]?.Diamonds_for_grand_prize,
                      "Diamonds for the grand prize"
                    )}
                    type="primary"
                    itemCount={0}
                    total={5}
                  ></PointsArea>
                  <PointsArea
                    count={
                      (user?.eligibility?.credits?.purchased || 0) +
                      (user?.eligibility?.credits?.subscription || 0)
                    }
                    icon="coins"
                    label={checkTranslationKey(
                      translations[lang]?.Coins,
                      "Coins"
                    )}
                    itemCount={1}
                    total={5}
                  ></PointsArea>
                  <PointsArea
                    count={user?.character?.skills?.lives}
                    icon="lives"
                    label={checkTranslationKey(
                      translations[lang]?.Lives,
                      "Lives"
                    )}
                    itemCount={2}
                    total={5}
                  ></PointsArea>
                  <PointsArea
                    count={user?.character?.level?.current}
                    icon="level"
                    label={checkTranslationKey(
                      translations[lang]?.Level,
                      "Level"
                    )}
                    itemCount={4}
                    total={5}
                  ></PointsArea>
                  <PointsArea
                    count={user?.character?.strike}
                    icon="strikes"
                    label={checkTranslationKey(
                      translations[lang]?.Strikes,
                      "Strikes"
                    )}
                    itemCount={3}
                    total={5}
                  ></PointsArea>
                </div>
              </div>
              <motion.div
                className="button-placement"
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
              >
                {demoCase && demoCase.case !== 1 && demoCase.case !== 2 ? (
                  <Button
                    buttonType="success"
                    disabled={disabled}
                    onClick={buyCredits ? handleBuyCredits : handlePlay}
                  >
                    {textButton}
                  </Button>
                ) : (
                  <Highlight>
                    <Button
                      buttonType="success"
                      disabled={disabled}
                      onClick={buyCredits ? handleBuyCredits : handlePlay}
                    >
                      {textButton}
                    </Button>
                  </Highlight>
                )}
              </motion.div>

              {description && (
                <div className="no-games-text">{description}</div>
              )}
            </motion.div>

            <div className="shop-area">
              <motion.div
                className="prizes"
                variants={prizesVariants}
                initial="hidden"
                animate="visible"
                custom={1}
              >
                <PrizeToWin />
              </motion.div>
              <motion.div
                className="game-store"
                variants={prizesVariants}
                initial="hidden"
                animate="visible"
                custom={2}
              >
                <ButtonStore />
              </motion.div>
            </div>
          </div>
        </div>
      </Background>
      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default Home;
