import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import { PrizeCard } from "../../ui/prize-card";
import "./prizes-view.scss";
import { Prizes } from "../../../shared/interfaces/prize.types";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { motion, Variants } from "framer-motion";

const prizesVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.2 * (custom + 1),
    },
  }),
};

export function PrizesView() {
  const prizesData = {
    items: [
      {
        id: "634030553922dc23210422b5",
        name: {
          en: "Grand Prize",
          fr: "Grand Prize",
          ar: "Grand Prize",
        },
        description: {
          en: "1 x BMW 3 Series",
          fr: "1 x BMW 3 Series",
          ar: "1 x BMW 3 Series",
        },
        image: "/img/prize/grand.png",
        quantity: 1,
        position: 1,
        template_id: 1,
        created_at: "2022-10-07T13:57:41.000000Z",
        updated_at: "2022-10-07T13:57:41.000000Z",
      },
      {
        id: "634030553922dc23210422b6",
        name: {
          en: "Strike & Win",
          fr: "Strike & Win",
          ar: "Strike & Win",
        },
        description: {
          en: "100 Megabytes",
          fr: "100 Megabytes",
          ar: "100 Megabytes",
        },
        image: "/img/prize/100mb.png",
        quantity: 1,
        position: 2,
        template_id: 2,
        created_at: "2022-10-07T13:57:41.000000Z",
        updated_at: "2022-10-07T13:57:41.000000Z",
      },
      {
        id: "634030553922dc23210422b7",
        name: {
          en: "Weekly Prize",
          fr: "Weekly Prize",
          ar: "Weekly Prize",
        },
        description: {
          en: "2 x Apple Watch",
          fr: "2 x Apple Watch",
          ar: "2 x Apple Watch",
        },
        image: "/img/prize/apple-watch.png",
        quantity: 2,
        position: 3,
        template_id: 3,
        created_at: "2022-10-07T13:57:41.000000Z",
        updated_at: "2022-10-07T13:57:41.000000Z",
      },
      {
        id: "634030553922dc23210422b8",
        name: {
          en: "Monthly Prize",
          fr: "Monthly Prize",
          ar: "Monthly Prize",
        },
        description: {
          en: "Cash",
          fr: "Cash",
          ar: "Cash",
        },
        image: "/img/prize/cash.png",
        quantity: 1,
        position: 4,
        template_id: 4,
        created_at: "2022-10-07T13:57:41.000000Z",
        updated_at: "2022-10-07T13:57:41.000000Z",
      },
    ],
  };

  const lang = useAppSelector(selectLanguage);
  console.log(lang);
  const translations = useAppSelector(selectTranslations);

  const renderPrizes = (prizes: Prizes["items"]) => {
    return prizes?.map((prize, index) => {
      return (
        <motion.div
          key={prize.id}
          variants={prizesVariants}
          initial="hidden"
          animate="visible"
          custom={index}
          className="prize-wrapper"
        >
          <PrizeCard
            type={prize.position === 1 ? "large" : "small"}
            image={prize.image}
            title={prize.name[lang]}
            subtitle={prize.description ? prize.description[lang] : ""}
          ></PrizeCard>
        </motion.div>
      );
    });
  };

  return (
    <div className="prizes-container">
      <Background type="geography">
        <NavBar hasAvatar />
        <div className="prizes-content">
          <Title>
            {checkTranslationKey(translations[lang]?.Prizes, "Prizes")}
          </Title>
          <div className="prizes-items">
            {prizesData && prizesData.items
              ? renderPrizes(prizesData.items)
              : null}
          </div>
        </div>
      </Background>
    </div>
  );
}

export default PrizesView;
