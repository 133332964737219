import { ComponentProps } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import "./coin-offer.scss";

export interface CoinOfferProps extends ComponentProps<"button"> {
  offerType?: "orange" | "purple" | "blue";
  heading?: string; //Activate stars and popular tag...
  price: string;
  coins: number;
}

export function CoinOfferButton({
  offerType,
  price,
  heading,
  coins,
  ...props
}: CoinOfferProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <>
      <div className="coin-offer-select">
        {heading ? <div className="popular-tag">{heading}</div> : null}
        <button
          {...props}
          className={`coin-offer coin-offer-${offerType || "orange"} ${
            props.className ?? ""
          }`}
        >
          {heading ? <div className="popular-stars"></div> : null}
          <div className="coin-offer-inner-overlay"></div>
          <div className="price-tag">
            <span>{coins}</span>
            {coins < 2
              ? checkTranslationKey(translations[lang]?.Coin, "Coin")
              : checkTranslationKey(translations[lang]?.Coins, "Coins")}
          </div>
        </button>
        <div className="coin-price">{price}</div>
      </div>
    </>
  );
}

export default CoinOfferButton;
