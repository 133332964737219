import React, { ComponentProps, useEffect } from "react";
import { Button } from "../../button";
import "./play-bonus-game.scss";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { Modal } from "../../modal";
import SoundManager from "../../../../utils/managers/sound-manager";

const bonusGameContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const iconsContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.8,
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1.1,
    },
  },
};

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

const iconVariants: Variants = {
  pulse: (custom: number) => ({
    scale: [1, 1.2, 1],
    transition: {
      repeatDelay: 3 - 1,
      duration: 1,
      repeat: Infinity,
      ease: "easeOut",
      delay: 1 * (1 + custom),
    },
  }),
};

export interface PlayBonusGameProps extends ComponentProps<"div"> {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  buttonClick: () => void;
}

const PlayBonusGame = ({
  modalOpen,
  setModalOpen,
  title,
  subtitle,
  description,
  buttonText,
  buttonClick,
}: PlayBonusGameProps) => {
  useEffect(() => {
    if (modalOpen) {
      SoundManager.vfxSounds.wow.play();
    } else {
      SoundManager.vfxSounds.wow.pause();
    }
  }, [modalOpen]);

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          id="modal-play-bonus-game"
          variants={bonusGameContainerVariants}
          initial={"hidden"}
          animate={"visible"}
          exit={"hidden"}
        >
          <Modal
            show={modalOpen}
            setShow={setModalOpen}
            hideCloseButton
            defaultAnimations={false}
            disableClickOutside
          >
            <motion.div
              className="play-bonus-game-container"
              variants={bgVariants}
              initial={"hidden"}
              animate={modalOpen && "visible"}
            >
              <div className="bonus-game-header">
                <div className="image-wrapper">
                  <img
                    src={"../img/bonus/mistery-box.png"}
                    alt=""
                    className="header-image"
                  />
                  <motion.div
                    className="icons-container"
                    variants={iconsContainerVariants}
                    initial={"hidden"}
                    animate={"visible"}
                  >
                    <motion.img
                      src="../img/bonus/diamond.png"
                      alt=""
                      className="icon-diamond"
                      variants={iconVariants}
                      animate="pulse"
                      custom={0}
                    />
                    <motion.img
                      src="../img/bonus/coin.png"
                      alt=""
                      className="icon-coin"
                      variants={iconVariants}
                      animate="pulse"
                      custom={1}
                    />
                    <motion.img
                      src="../img/bonus/heart.png"
                      alt=""
                      className="icon-heart"
                      variants={iconVariants}
                      animate="pulse"
                      custom={2}
                    />
                  </motion.div>
                </div>
              </div>
              <motion.div
                className="bonus-game-content"
                variants={contentVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <div className="bonus-game-subtitle">{subtitle}</div>
                <div className="bonus-game-title">{title}</div>
                <div className="bonus-game-text">{description}</div>
                <div className="share-area">
                  <Button buttonType="success" onClick={buttonClick}>
                    {buttonText}
                  </Button>
                </div>
              </motion.div>
            </motion.div>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PlayBonusGame;
