import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../shared/data-access/store/store";
import { authApi } from "./services/auth.service";
import {
  NotificationsResponse,
  User,
} from "../../../shared/interfaces/auth.types";

const accessToken = localStorage.getItem("accessToken");

type AuthState = {
  user: User | null;
  isAuthenticated: boolean;
  token: string;
  notifications: NotificationsResponse["items"] | null;
  notificationsDelayTime: number;
  forceNotification: boolean;
};

const initialState: AuthState = {
  // @ts-ignore
  user: {
    eligibility: {
      authentication: "automatic",
      subscriptionStatus: "ACTIVE",
      eligible: true,
      canPlay: true,
      expires: "2022-10-08T02:59:59+03:00",
      renewal: "2022-10-07T02:59:59+03:00",
      credits: {
        subscription: 2,
        purchased: 1,
      },
    },
    character: {
      id: "63443500a5e76374d80d6d0e",
      points: 50,
      position: 1,
      resetTime: "2022-10-09T21:00:00Z",
      nickname: "User21433898",
      avatar:
        "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Female-08.png",
      level: {
        current: 1,
        currentThreshold: 39001,
        nextThreshold: 45500,
        percentage: 23,
      },
      skills: {
        lives: 1,
        items: {
          hide: {
            available: true,
          },
          skip: {
            available: true,
          },
          ask_genie: {
            available: true,
          },
        },
      },
      strike: 0,
      achievements: [],
    },
    session: {
      id: "63443500bf07efe923086105",
      date: "2022-10-10",
      credits_used: 1,
      updated_at: "2022-10-10T15:06:45.795000Z",
      created_at: "2022-10-10T15:06:40.719000Z",
    },
    settings: [],
  },
  isAuthenticated: false,
  token: accessToken || "",
  notifications: null,
  notificationsDelayTime: 0,
  forceNotification: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = initialState.user;
      state.isAuthenticated = false;
      state.token = "";
    },
    tokenReceived: (state, action) => {
      state.token = action.payload;
    },
    removeNotification: (state, action) => {
      state.notifications =
        state.notifications?.filter(
          (notification) => notification.id !== action.payload.id
        ) || null;
    },
    delayNotifications: (state, action) => {
      state.notificationsDelayTime = action.payload;
    },
    forceNotificationAction: (state, action) => {
      state.forceNotification = action.payload;
    },
    updateNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          if (payload.apikey) {
            state.isAuthenticated = true;
            state.token = payload.apikey;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.loginWithToken.matchFulfilled,
        (state, { payload }) => {
          if (payload.apikey) {
            state.isAuthenticated = true;
            state.token = payload.apikey;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.getUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
          state.isAuthenticated = true;
        }
      )
      .addMatcher(authApi.endpoints.getUser.matchRejected, (state) => {
        state.user = initialState.user;
        state.isAuthenticated = false;
      })
      .addMatcher(
        authApi.endpoints.putUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(
        authApi.endpoints.getNotifications.matchFulfilled,
        (state, { payload }) => {
          state.notifications = payload.items;
        }
      );
  },
});

const { reducer, actions } = authSlice;

export const {
  logout,
  tokenReceived,
  removeNotification,
  delayNotifications,
  forceNotificationAction,
  updateNotifications,
  updateUser,
} = actions;

export const selectAuthState = (state: RootState): AuthState => state.auth;

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
);

export const selectToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const selectNotifications = (state: RootState) =>
  state.auth.notifications;
export const selectNotificationsDelayTime = (state: RootState) =>
  state.auth.notificationsDelayTime;
export const selectForceNotification = (state: RootState) =>
  state.auth.forceNotification;

export default reducer;
