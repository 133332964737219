import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../../../../auth/data-access/store/authSlice";
import { selectUserId } from "../../general/generalSlice";
import {
  selectGameAndEventError,
  selectGameLoaded,
  selectGameState,
  updateError,
  updateEventResponse,
  updateGame,
  reset,
} from "../gameSlices";
import { SocketService } from "../services/socket.service";
import {
  SocketRequest,
  SocketResponse,
  SocketResponses,
  socketTypeAssert,
  socketTypeError,
} from "../types/requests";
import { useLazyGetUserQuery } from "../../../../../auth/data-access/store/services/auth.service";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../message/messageSlice";

let instances = 0;

/**
 * This hook is in charge of handle all events from the socket
 * and to make sure the game is loaded. If this hook is not used,
 * the store will not be updated. This hook is only allowed to be
 * used once. If there are more than one instance, an error will be
 * thrown.
 */
export const useGame = () => {
  const socketService = useMemo(() => new SocketService(), []);
  const isLoaded = useSelector(selectGameLoaded);
  const gameState = useSelector(selectGameState);
  const error = useSelector(selectGameAndEventError("init_game"));
  const token = useSelector(selectToken);
  const uuid = useSelector(selectUserId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getUser, { isSuccess: userIsSuccess, isFetching: userIsFetching }] =
    useLazyGetUserQuery();

  useEffect(() => {
    if (instances) {
      throw new Error("Only one instance of useGame is allowed");
    }
    instances++;
    return () => {
      instances--;
    };
  }, []);

  useEffect(() => {
    if (userIsSuccess) {
      navigate("/");
    }
  }, [userIsFetching]);

  const resetGame = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const send = useCallback(
    async (force = false) => {
      if (!token) {
        return;
      }
      if (isLoaded && !force) {
        return;
      }

      const promise = new Promise<SocketResponse<"init_game">>(
        (resolve, reject) => {
          const listener = socketService.on(
            "response",
            (d: SocketResponses) => {
              socketService.off(listener);
              if (socketTypeError(d) && d.call === "init_game") {
                reject(d);
              } else if (
                socketTypeAssert<SocketResponse<"init_game">>("init_game", d)
              ) {
                resolve(d);
              }
            }
          );
        }
      );

      socketService.emit({
        call: "init_game",
        params: {
          uuid: uuid,
          userToken: token,
        },
      } as SocketRequest<"init_game">);

      return promise;
    },
    [socketService, token, uuid, isLoaded]
  );

  useEffect(() => {
    socketService.on("error_code", (d: SocketResponses) => {
      // @ts-ignore
      if (d.error_header === 401) {
        getUser();
        dispatch(
          setMessage({
            type: "error",
            content:
              "Oops! It seems that your session expired, please try again",
          })
        );
      }
      if (socketTypeError(d)) {
        dispatch(updateError(d));
      }
    });
    /**
     * This hook is in charge of handle all events from the socket
     * and update the store. If this hook is not used, the store will
     * not be updated.
     */
    socketService.on("response", (d: SocketResponses) => {
      if (socketTypeError(d)) {
        return dispatch(updateError(d));
      }
      if (socketTypeAssert<SocketResponse<"init_game">>("init_game", d)) {
        dispatch(updateGame(d.data));
      } else {
        if (socketTypeAssert<SocketResponse<"finish_game">>("finish_game", d)) {
          d.data.finished = d.data.status === "finished";
        }

        if (socketTypeAssert<SocketResponse<"get_ladder">>("get_ladder", d)) {
          if (!d.data.finished) {
            d.data.player.position += Number(d.data.player.can_advance);
            d.data.opponent.position += Number(d.data.opponent.can_advance);
          }
        }

        dispatch(updateEventResponse(d));
      }
    });

    socketService.on(
      "opponent_found",
      (d: Omit<SocketResponse<"get_opponent">, "call">) => {
        dispatch(updateEventResponse({ call: "get_opponent", ...d }));
      }
    );

    socketService.on(
      "bonus_opponent_found",
      (d: Omit<SocketResponse<"get_bonus_opponent">, "call">) => {
        dispatch(
          updateEventResponse({ call: "get_bonus_opponent", data: d.data })
        );
      }
    );
  }, [dispatch, socketService]);

  const finish = useCallback(
    async (force = false) => {
      const { game } = gameState || { game: null };
      if (!game) {
        return;
      }
      const promise = new Promise<SocketResponse<"finish_game">>(
        (resolve, reject) => {
          const listener = socketService.on(
            "response",
            (d: SocketResponses) => {
              if (socketTypeError(d) && d.call === "finish_game") {
                socketService.off(listener);
                reject(d);
              } else if (
                socketTypeAssert<SocketResponse<"finish_game">>(
                  "finish_game",
                  d
                )
              ) {
                socketService.off(listener);
                resolve(d);
              }
            }
          );
        }
      );
      setTimeout(() => {
        socketService.emit({
          call: "finish_game",
          params: {
            uuid: uuid,
            userToken: token,
            playedGame: {
              id: game.id,
            },
            ...(force ? { force_finish: true } : {}),
          },
        } as SocketRequest<"finish_game">);
      }, 3000);

      return promise;
    },
    [socketService, token, uuid, gameState]
  );

  return {
    gameState,
    send,
    error,
    resetGame,
    finish,
  };
};
