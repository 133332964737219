import { RootState } from "./store";
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import {
  logout,
  tokenReceived,
} from "../../../auth/data-access/store/authSlice";
import { Mutex } from "async-mutex";

function appendQueryStringParam(
  args: string | FetchArgs,
  key: string,
  value: string
): string | FetchArgs {
  let urlEnd = typeof args === "string" ? args : args.url;

  if (urlEnd.indexOf("?") < 0) {
    urlEnd += "?";
  } else {
    urlEnd += "&";
  }

  urlEnd += `${key}=${value}`;

  return typeof args === "string" ? urlEnd : { ...args, url: urlEnd };
}

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.REACT_APP_API_URL ??
    "https://api.battleup.dev1.beecoded.ro/api/v1/",
  prepareHeaders: (headers, { getState }) => {
    if (!headers.has("accept")) {
      headers.set("accept", "application/json");
    }

    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

// create a new mutex
const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // append uuid param to every request
  const state = api.getState() as RootState;
  const uuid = state.general.uuid;

  args = appendQueryStringParam(args, "uuid", uuid);
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const tokenForLogin = localStorage.getItem("tokenForLogin");

      const logoutHandler = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenForLogin");
        api.dispatch(logout());
      };

      try {
        if (tokenForLogin) {
          // const loginWithTokenRes = await api.dispatch(authApi.endpoints.loginWithToken.initiate({token: tokenForLogin})).unwrap();
          const loginWithTokenRes = await baseQuery(
            {
              url: "user/login-via-token",
              method: "POST",
              body: { token: tokenForLogin },
            },
            api,
            extraOptions
          );

          if (loginWithTokenRes.data) {
            // retry the initial query
            const apikey = (
              loginWithTokenRes as Awaited<
                ReturnType<BaseQueryFn<null, { apikey: string }>>
              >
            ).data?.apikey;
            if (apikey) {
              localStorage.setItem("accessToken", apikey);
              api.dispatch(tokenReceived(apikey));
            }
            result = await baseQuery(args, api, extraOptions);
          } else {
            logoutHandler();
          }
        } else {
          logoutHandler();
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default baseQueryWithReauth;
