import useComponentVisible from "../../../hooks/useComponentVisible";
import cx from "classnames";

import "./highlight.scss";

type HighlightProps = React.HTMLProps<HTMLDivElement>;

const Highlight = (props: HighlightProps) => {
  const { children, className } = props;
  const { ref, isComponentHighlight } = useComponentVisible();

  return (
    <div
      className={cx(`highlight ${isComponentHighlight ? "on" : ""}`, className)}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default Highlight;
