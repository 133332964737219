import { useNavigate } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import { checkTranslationKey } from "../../utils/translation";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  DemoCase,
  selectLanguage,
  selectTranslations,
  updateDemoCase,
} from "../../shared/data-access/store/general/generalSlice";
import { Background } from "../../shared/ui/background";

import "./intro.scss";
import {
  selectMenuModal,
  setModal,
} from "../../shared/data-access/store/ui/uiSlice";
import Menu from "../../menu/feature/menu";
import useLocals from "../../utils/hooks/use-locals";
import { selectUser, updateUser } from "../../auth/data-access/store/authSlice";
import { User } from "../../shared/interfaces/auth.types";
import { useState } from "react";

const logoVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      ease: "easeOut",
    },
  },
};

export function Intro() {
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const menuModal = useAppSelector(selectMenuModal);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const { reset: resetLocals } = useLocals();
  const { setValue, getValue } = useLocals();
  const [mute, setMute] = useState<string | null>(getValue("soundsDisabled"));

  const handleChooseDemo = (selectedCase: DemoCase["case"]) => {
    resetLocals();
    switch (selectedCase) {
      case 1:
        dispatch(updateDemoCase({ case: selectedCase, step: 1 }));
        navigate("/onboarding");
        break;
      case 2:
        dispatch(updateDemoCase({ case: selectedCase, step: 1 }));
        if (user) {
          dispatch(
            updateUser({
              ...user,
              eligibility: {
                ...user.eligibility,
                credits: {
                  ...user.eligibility.credits,
                  subscription: 1,
                },
              },
              character: {
                ...user.character,
                points: 150,
                skills: {
                  ...user.character.skills,
                  lives: 3,
                },
              },
            } as User)
          );
        }
        navigate("/home");
        break;
      case 3:
        dispatch(updateDemoCase({ case: selectedCase, step: 1 }));
        break;
    }
  };

  return (
    <div id="intro">
      <Background type="home">
        <div className="intro-content">
          <div className={`modal-menu ${menuModal ? "open" : ""}`}>
            <Menu />
          </div>
          <div className="header-transparent">
            <motion.img
              src="/img/batteup-logo.png"
              alt="BattleUp Logo"
              height={52}
              className="logo"
              variants={logoVariants}
              initial="hidden"
              animate="visible"
            />
          </div>
          <motion.div
            variants={contentVariants}
            initial="hidden"
            animate="visible"
          >
            <p className="title-intro">
              {checkTranslationKey(translations[lang]?.Use_cases, "Use cases")}
            </p>
            <p className="description">
              {checkTranslationKey(
                translations[lang]?.Experience_Battle_Up_with,
                "Experience Battle Up with 3 interesting use cases"
              )}
            </p>
            <div className="use-cases">
              <div onClick={() => handleChooseDemo(1)} className="btn-case">
                <span>1</span>
                <p>
                  {checkTranslationKey(
                    translations[lang]?.Opponent_loses_at_2nd_question,
                    `Opponent loses at 2nd question \n User reached and loses at 3rd question + Bonus game`
                  )}
                </p>
              </div>
              <div onClick={() => handleChooseDemo(2)} className="btn-case">
                <span>2</span>
                <p>
                  {checkTranslationKey(
                    translations[lang]?.Opponent_loses_at_5th_question,
                    "Opponent loses at 5th question \n User loses at 7th question, reaching the 1st cushion + Level Up"
                  )}
                </p>
              </div>
              <button
                className="btn-case"
                onClick={() => {
                  handleChooseDemo(3);
                  dispatch(setModal({ modal: "menu", value: true }));
                }}
              >
                <span>3</span>
                <p>
                  {checkTranslationKey(
                    translations[lang]?.Basic_screens_on_the_Hamburger_menu,
                    "Basic screens on \n the Hamburger menu"
                  )}
                </p>
              </button>
            </div>

            <div className="mute-sounds">
              {mute ? (
                <img
                  src="/img/icns/volume-mute-fill.svg"
                  alt=""
                  onClick={() => {
                    setValue("soundsDisabled", null);
                    setMute(null);
                  }}
                />
              ) : (
                <img
                  src="/img/icns/volume-up-fill.svg"
                  alt=""
                  onClick={() => {
                    setValue("soundsDisabled", "yes");
                    setMute("yes");
                  }}
                />
              )}
            </div>
          </motion.div>
        </div>
      </Background>
    </div>
  );
}

export default Intro;
