import { useEffect, useRef } from "react";
import "./modal.scss";
import ButtonClose from "../button-close/button-close";
import { motion, Variants } from "framer-motion";

const rayVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

export interface ModalProps {
  setShow: (show: boolean) => void;
  show: boolean;
  hideCloseButton?: boolean;
  children: Array<JSX.Element> | JSX.Element | string;
  hasLights?: boolean;
  defaultAnimations?: boolean;
  disableClickOutside?: boolean;
}

export function Modal({
  setShow,
  show,
  hideCloseButton,
  children,
  hasLights,
  defaultAnimations = true,
  disableClickOutside,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickOutsideContent = (e: MouseEvent) => {
      if (e.target === modalRef.current) {
        setShow(false);
      }
    };

    if (!disableClickOutside) {
      window.addEventListener("click", clickOutsideContent);
    }
    return () => {
      if (!disableClickOutside) {
        window.removeEventListener("click", clickOutsideContent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={modalRef}
      className={`modal ${show ? "active" : ""}`}
      style={{ transition: defaultAnimations ? "0.3s ease-in-out" : "" }}
    >
      {hasLights ? (
        <>
          <motion.img
            src="../img/level-up/lights-top.svg"
            alt=""
            className="lights lights-top"
            variants={rayVariants}
            initial={"hidden"}
            animate={show && "visible"}
          />
          <motion.img
            src="../img/level-up/lights-bottom.svg"
            alt=""
            className="lights lights-bottom"
            variants={rayVariants}
            initial={"hidden"}
            animate={show && "visible"}
          />
        </>
      ) : null}

      <div className="modal__content">
        {!hideCloseButton && <ButtonClose onClick={() => setShow(false)} />}
        {children}
      </div>
    </div>
  );
}

export default Modal;
