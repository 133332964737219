import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGameEvents } from "../../../shared/data-access/store/game/hooks/use-game-events";
import { Background } from "../../../shared/ui/background";
import { Modal } from "../../../shared/ui/modal";
import { NavBar } from "../../../shared/ui/navbar";
import { GenieModal } from "../../ui/ask-genie";
import { GameplayAnswer } from "../../ui/gameplay-answer";
import { GameplayLayout } from "../../ui/gameplay-layout";
import { Hints } from "../../ui/hints";
import { PrizeQuestion } from "../../ui/question-prize";
import "./gameplay-guess-it.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectDemoCase,
  selectLanguage,
  updateDemoCase,
} from "../../../shared/data-access/store/general/generalSlice";
import cx from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useCountdown } from "../../../utils/hooks/use-countdown";
import { AnimatePresence, motion, Variants } from "framer-motion";
import {
  Game,
  SkillTypes,
} from "../../../shared/data-access/store/game/types/game-types";
import { Badge } from "../../../shared/ui/badge";
import { bonusResultContainerVariants } from "../../utils/animation-states";
import {
  UseSkillEvent,
  UseSkillResponse,
} from "../../../shared/data-access/store/game/types/socket-events/use-skill";
import { differenceInSeconds } from "date-fns";
import { SocketResponse } from "../../../shared/data-access/store/game/types/requests";
import { StartRoundEvent } from "../../../shared/data-access/store/game/types/socket-events/start-round";
import { categoryMock } from "../../../category/feature/category-view";
import { Categories } from "../../../shared/data-access/store/game/types/socket-events/category";
import { initGameMock } from "../../../ladder/feature";
import { UpdateRoundAnswerEvent } from "../../../shared/data-access/store/game/types/socket-events/update-round-answer";
import Highlight from "../../../shared/ui/highlight/highlight";
import SoundManager from "../../../utils/managers/sound-manager";
import {
  startRound1Mock,
  startRound2Mock,
  startRound3Mock,
  answer1Mock,
  answer2Mock,
  answer3Mock,
  startRound1Case2Mock,
  startRound2Case2Mock,
  answer1Case2Mock,
  answer2Case2Mock,
  answer3Case2Mock,
  startRound3Case2Mock,
  answer4Case2Mock,
  startRound4Case2Mock,
  startRound5Case2Mock,
  answer5Case2Mock,
  startRound6Case2Mock,
  answer6Case2Mock,
  startRound7Case2Mock,
  answer7Case2Mock,
  startRound5Case2MockSkip,
} from "./mocks";
import {
  selectUser,
  updateUser,
} from "../../../auth/data-access/store/authSlice";
import { User } from "../../../shared/interfaces/auth.types";

const optionsVariants: Variants = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: (custom) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.1,
      delay: 0.1 * (custom + 1),
    },
  }),
};

const ANIMATION_TIME = 1;

export function GameplayGuessIt() {
  const demoCase = useAppSelector(selectDemoCase);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const gameType: "classic" | "bonus" = useMemo(
    () => (location.pathname.includes("bonus") ? "bonus" : "classic"),
    [location.pathname]
  );
  const [showGenie, setShowGenie] = useState(false);
  const [answered, setAnswered] = useState(false);
  const { meta } = useGameEvents("start_round");
  // @ts-ignore
  const game: Game = initGameMock;
  const gameLoaded = true;
  const [startRoundHide, setStartRoundHide] = useState<
    typeof startRound | null
  >(null);
  const [startRoundSkip, setStartRoundSkip] = useState<
    typeof startRound | null
  >(null);

  let answerMock =
    demoCase && demoCase.case === 1
      ? answer1Mock
      : demoCase && demoCase.case === 2
      ? answer1Case2Mock
      : answer1Mock;

  let startRound =
    demoCase && demoCase.case === 1
      ? startRound1Mock
      : demoCase && demoCase.case === 2
      ? startRound1Case2Mock
      : startRound1Mock;

  if (demoCase && demoCase.case === 1) {
    if (demoCase.step === 9) {
      // @ts-ignore
      startRound = startRound2Mock;
      answerMock = answer2Mock;
      game.current_round_index = 1;
    } else if (demoCase.step === 11) {
      // @ts-ignore
      startRound = startRound3Mock;
      // @ts-ignore
      answerMock = answer3Mock;
      game.current_round_index = 2;
    }
  } else if (demoCase && demoCase.case === 2) {
    if (demoCase.step === 7 || demoCase.step === 8) {
      // @ts-ignore
      startRound = startRound2Case2Mock;
      answerMock = answer2Case2Mock;
      game.current_round_index = 1;
    } else if (demoCase.step === 10) {
      // @ts-ignore
      startRound = startRound3Case2Mock;
      answerMock = answer3Case2Mock;
      game.current_round_index = 2;
    } else if (demoCase.step === 13) {
      // @ts-ignore
      startRound = startRound4Case2Mock;
      answerMock = answer4Case2Mock;
      game.current_round_index = 3;
    } else if (demoCase.step === 15 || demoCase.step === 16) {
      // @ts-ignore
      startRound = startRound5Case2Mock;
      answerMock = answer5Case2Mock;
      game.current_round_index = 4;
    } else if (demoCase.step === 18 || demoCase.step === 19) {
      // @ts-ignore
      startRound = startRound6Case2Mock;
      answerMock = answer6Case2Mock;
      game.current_round_index = 5;
    } else if (demoCase.step === 22) {
      // @ts-ignore
      startRound = startRound7Case2Mock;
      answerMock = answer7Case2Mock;
      game.current_round_index = 6;
    }
  }
  const data: SocketResponse<"start_round", StartRoundEvent> = {
    // @ts-ignore
    data: startRoundHide || startRoundSkip || startRound,
  };
  const [results, setResults] = useState<
    SocketResponse<"update_round_answer", UpdateRoundAnswerEvent> | undefined
  >(undefined);
  // const { send: replenish } = useGameEvents("replenish_skill");

  const categoryData = {
    data: categoryMock,
  };

  const [skillResult, setSkillResult] = useState<
    Pick<UseSkillResponse, "skill_result">["skill_result"]
  >({});

  const question = useMemo(
    () => skillResult.new_question || data?.data?.question,
    [data?.data?.question, skillResult.new_question]
  );
  const timer = question?.timer;
  const lang = useAppSelector(selectLanguage);
  const { uuid } = meta;
  const points = useMemo(
    () => (game ? game.ladder_points[game.current_round_index].value : 0),
    [gameLoaded]
  );
  const navigate = useNavigate();
  const replanishingLockRef = useRef(false);
  const finished = game?.finished;
  const currentTimeRef = useRef<number>();
  const startGennieDateRef = useRef<Date>();
  const {
    timeLeft,
    start,
    stop,
    clean,
    add: addTime,
    reload: reloadTimer,
  } = useCountdown(timer);

  const isImageQuestion = () =>
    question && question.answers[0].type === "image";

  const goToLadder = (shouldFinish: boolean) => {
    updateStepForAnswer();
    navigate("/ladder", { state: { shouldFinish } });
  };

  const correctAnswer = useMemo(
    () =>
      answered
        ? {
            correct_answer_index: answerMock.round.correct_answer_index,
            answer_index: answerMock.round.answer_index,
          }
        : false,
    [answerMock, answered]
  );

  const updateStepForAnswer = () => {
    if (demoCase && demoCase.case === 1) {
      if (demoCase.step === 7) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 8 }));
      } else if (demoCase.step === 9) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 10 }));
      } else if (demoCase.step === 11) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 12 }));
      }
    } else if (demoCase && demoCase.case === 2) {
      if (demoCase.step === 5) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 6 }));
      } else if (demoCase.step === 8) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 9 }));
      } else if (demoCase.step === 10) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 11 }));
      } else if (demoCase.step === 13) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 14 }));
      } else if (demoCase.step === 16) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 17 }));
      } else if (demoCase.step === 19) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 20 }));
      } else if (demoCase.step === 22) {
        dispatch(updateDemoCase({ case: demoCase.case, step: 23 }));
      }
    }
  };

  const handleAnswer = useCallback(
    async () => {
      if (!currentTimeRef.current === undefined || answered) {
        return;
      }

      setAnswered(true);

      stop();
      clean();

      setResults({ call: "update_round_answer", data: answerMock });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [game, goToLadder, stop]
  );

  const handleSkillCallback = (skill: SkillTypes) => {
    return async () => {
      if (demoCase && demoCase.case === 2) {
        if (demoCase.step === 7 && skill === "hide") {
          game.skills.items.hide.available = false;
          setStartRoundHide({
            ...startRound,
            question: {
              ...startRound.question,
              answers: [
                startRound.question.answers[0],
                startRound.question.answers[1],
              ],
            },
          });
          dispatch(updateDemoCase({ case: demoCase.case, step: 8 }));
        } else if (demoCase.step === 15 && skill === "skip") {
          game.skills.items.skip.available = false;
          setStartRoundSkip(startRound5Case2MockSkip);
          dispatch(updateDemoCase({ case: demoCase.case, step: 16 }));
        }
      }

      if (
        demoCase &&
        ((demoCase.case === 1 && demoCase.step !== 6) ||
          (demoCase.case === 1 &&
            demoCase.step === 6 &&
            skill !== "ask_genie") ||
          (demoCase.case === 2 &&
            demoCase.step !== 7 &&
            demoCase.step !== 18) ||
          (demoCase.case === 2 && demoCase.step === 7 && skill !== "hide") ||
          (demoCase.case === 2 && demoCase.step === 15 && skill !== "skip") ||
          (demoCase.case === 2 &&
            demoCase.step === 18 &&
            skill !== "ask_genie"))
      ) {
        return;
      }

      if (answered) {
        return;
      }
      const skillData: SocketResponse<"use_skill", UseSkillEvent> = {
        data: {
          current_round_index: 0,
          id: "63457ee30db2fc55ba0e1e43",
          skill_result: {
            correct_answer_index: 1,
          },
        },
        call: "use_skill",
      };

      const result = skillData.data.skill_result;
      if (result) {
        if (result.new_question) {
          setSkillResult(result);
        } else {
          setSkillResult((prev) => ({ ...result, ...prev }));
        }
      }

      if (skill === "skip") {
        SoundManager.vfxSounds.skip.play();
        reloadTimer();
      }
      if (skill === "ask_genie") {
        SoundManager.vfxSounds.genie.play();
        startGennieDateRef.current = new Date();
        stop();
        setShowGenie(true);
      }
      if (skill === "hide") {
        SoundManager.vfxSounds.hide.play();
      }
    };
  };

  const handleReplenishCallback = (skill: SkillTypes) => {
    if (!game || !uuid || answered || replanishingLockRef.current) {
      return;
    }
    replanishingLockRef.current = true;
    if (user && user.character.skills.lives >= 1) {
      dispatch(
        updateUser({
          ...user,
          character: {
            ...user.character,
            skills: {
              ...user.character.skills,
              lives: user.character.skills.lives - 1,
            },
          },
        } as User)
      );
    }

    game.skills.items[skill].available = true;

    // await replenish({
    //   playedGame: {
    //     id: game.id,
    //     current_round_index: game.current_round_index,
    //   },
    //   userToken: token,
    //   uuid,
    //   skill_code: skill,
    // });
    replanishingLockRef.current = false;
  };

  useEffect(() => {
    if (demoCase && demoCase.case === 2 && demoCase.step === 5) {
      game.skills.items.ask_genie.available = false;
    }
  }, []);

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    if (timeLeft < 10) {
      SoundManager.vfxSounds.clock.playbackRate = 1.2;
    }

    if (timeLeft > 0) {
      currentTimeRef.current = timeLeft;
      SoundManager.vfxSounds.clock.play();
      if (timeLeft === 2) {
        // stop();
        // handleAnswer();
        reloadTimer();
      }
    } else {
      if (timeLeft === 0) {
        if (currentTimeRef.current && currentTimeRef.current > 0) {
          currentTimeRef.current = 0;
        }
      } else {
        if (!currentTimeRef.current) {
          currentTimeRef.current = 0;
        }
        // stop();
        // handleAnswer();
        reloadTimer();
      }
    }
  }, [timeLeft]);

  useEffect(() => {
    if (showGenie) {
      const timeout = setTimeout(() => {
        addTime(8 + ANIMATION_TIME);
        setShowGenie(false);
      }, 8000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showGenie]);

  useEffect(() => {
    if (!question) {
      stop();
    } else {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    if (gameLoaded) {
      if (finished) {
        setTimeout(() => {
          goToLadder(finished);
        }, 3000);
        //should reload game
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameLoaded]);

  useEffect(() => {
    // @ts-ignore
    if (results?.data) {
      setTimeout(() => {
        // @ts-ignore
        goToLadder(results.data.finished);
      }, 3000);
    }
  }, [results]);

  useEffect(() => {
    if (results) {
      if (results?.data.round.correct) {
        SoundManager.vfxSounds.correct.play();
      } else if (!results?.data.round.correct) {
        SoundManager.vfxSounds.wrong.play();
      }
    }
  }, [results]);

  useEffect(() => {
    if (answered) {
      SoundManager.vfxSounds.clock.pause();
      SoundManager.vfxSounds.clock.playbackRate = 1;
      SoundManager.vfxSounds.clock.currentTime = 0;
    }
  }, [answered]);

  const renderGenieModal = () => (
    <Hints
      title="Ask Genie"
      icon="genie"
      disabled={!game?.skills.items.ask_genie.available}
      onClick={handleSkillCallback("ask_genie")}
      bottom={
        !game?.skills.items.ask_genie.available ? (
          <>
            {demoCase && demoCase.case === 2 && demoCase.step === 18 ? (
              <Highlight>
                <Badge
                  type="success"
                  aspect="rectangle"
                  onClick={() => handleReplenishCallback("ask_genie")}
                >
                  <>
                    <img src="/img/icns/lives.svg" alt="" />1
                  </>
                </Badge>
              </Highlight>
            ) : (
              <Badge type="success" aspect="rectangle" onClick={() => false}>
                <>
                  <img src="/img/icns/lives.svg" alt="" />1
                </>
              </Badge>
            )}
          </>
        ) : undefined
      }
    ></Hints>
  );

  const renderHideSkill = () => (
    <Hints
      title="Hide"
      icon="bomb"
      disabled={!game?.skills.items.hide.available}
      onClick={handleSkillCallback("hide")}
      bottom={
        !game?.skills.items.hide.available ? (
          <Badge type="success" aspect="rectangle" onClick={() => false}>
            <>
              <img src="/img/icns/lives.svg" alt="" />1
            </>
          </Badge>
        ) : undefined
      }
    ></Hints>
  );

  const renderSkipSkill = () => (
    <Hints
      title="Skip"
      icon="skip"
      disabled={!game?.skills.items.skip.available}
      onClick={handleSkillCallback("skip")}
      bottom={
        !game?.skills.items.skip.available ? (
          <Badge type="success" aspect="rectangle" onClick={() => false}>
            <>
              <img src="/img/icns/lives.svg" alt="" />1
            </>
          </Badge>
        ) : undefined
      }
    ></Hints>
  );

  if (!categoryData?.data) {
    return null;
  }

  return (
    <div className="gameplay-page">
      <Background
        type={
          categoryData && categoryData.data?.category?.code
            ? (categoryData.data.category?.code as Categories)
            : "defaultGame"
        }
        category={categoryData.data.category?.gradient_background}
      >
        <NavBar hideMenu slotEnd="lives" />
        <GameplayLayout
          timer={currentTimeRef.current}
          prizes={
            <PrizeQuestion
              icon="diamonds"
              category={categoryData?.data?.category?.icon || ""}
            >
              {points}
            </PrizeQuestion>
          }
          question={
            gameLoaded
              ? (question?.content[lang] || question?.content.en) ?? ""
              : "Loading question..."
          }
          hint={
            gameType === "classic" ? (
              <>
                {demoCase && demoCase.case === 2 && demoCase.step === 7 ? (
                  <Highlight>{renderHideSkill()}</Highlight>
                ) : (
                  renderHideSkill()
                )}
                {demoCase &&
                ((demoCase.case === 1 &&
                  demoCase.step === 6 &&
                  game.skills.items.ask_genie.available) ||
                  (demoCase.case === 2 && demoCase.step === 18)) ? (
                  <Highlight>{renderGenieModal()}</Highlight>
                ) : (
                  renderGenieModal()
                )}
                {demoCase && demoCase.case === 2 && demoCase.step === 15 ? (
                  <Highlight>{renderSkipSkill()}</Highlight>
                ) : (
                  renderSkipSkill()
                )}
              </>
            ) : undefined
          }
        >
          {/* If the options are type image add class .options-images on the .options div */}
          <div
            className={cx("options", {
              "options-images": isImageQuestion(),
            })}
          >
            {question?.answers
              ?.map((answer, index) => {
                const key = `${answer.type}-${index}`;
                const status = correctAnswer
                  ? (cx({
                      correct: correctAnswer.correct_answer_index === index,
                      wrong:
                        correctAnswer.correct_answer_index !== index &&
                        correctAnswer.answer_index === index,
                      default:
                        correctAnswer.correct_answer_index !== index &&
                        correctAnswer.answer_index !== index,
                    }) as "correct" | "wrong" | "default")
                  : "default";
                if (answer.type === "text") {
                  const answerText = (
                    <motion.div
                      key={key}
                      className="answer-wrapper"
                      variants={optionsVariants}
                      initial="hidden"
                      animate="visible"
                      custom={index}
                    >
                      <GameplayAnswer
                        gameplay="text"
                        status={status}
                        options={{
                          text: answer.value[lang] || answer.value.en,
                          badgeType: gameType === "bonus" ? "outer" : "inner",
                        }}
                        onClick={() => {
                          if (
                            demoCase &&
                            demoCase.case === 1 &&
                            (demoCase.step === 6 ||
                              (demoCase.step === 7 && index !== 1) ||
                              (demoCase.step === 9 && index !== 0) ||
                              (demoCase.step === 11 && index !== 0))
                          ) {
                            return null;
                          } else if (
                            demoCase &&
                            demoCase.case === 2 &&
                            ((demoCase.step === 5 && index !== 2) ||
                              demoCase.step === 7 ||
                              (demoCase.step === 8 && index !== 0) ||
                              (demoCase.step === 10 && index !== 3) ||
                              demoCase.step === 15 ||
                              demoCase.step === 18 ||
                              (demoCase.step === 19 && index !== 1) ||
                              (demoCase.step === 22 && index !== 3))
                          ) {
                            return null;
                          } else {
                            handleAnswer();
                          }
                        }}
                        disabled={!!correctAnswer}
                      />
                    </motion.div>
                  );

                  if (
                    demoCase &&
                    demoCase.case === 1 &&
                    ((demoCase.step === 7 && index === 1) ||
                      (demoCase.step === 9 && index === 0))
                  ) {
                    return (
                      <Highlight key={`answer-${index}`}>
                        {answerText}
                      </Highlight>
                    );
                  } else if (
                    demoCase &&
                    demoCase.case === 2 &&
                    ((demoCase.step === 5 && index === 2) ||
                      (demoCase.step === 8 && index === 0) ||
                      (demoCase.step === 10 && index === 3) ||
                      (demoCase.step === 16 && index === 1) ||
                      (demoCase.step === 19 && index === 1) ||
                      (demoCase.step === 22 && index === 3))
                  ) {
                    return (
                      <Highlight key={`answer-${index}`}>
                        {answerText}
                      </Highlight>
                    );
                  } else {
                    return answerText;
                  }
                } else if (answer.type === "image") {
                  const answerImage = (
                    <motion.div
                      key={key}
                      className="answer-wrapper"
                      variants={optionsVariants}
                      initial="hidden"
                      animate="visible"
                      custom={index}
                    >
                      <GameplayAnswer
                        gameplay="image"
                        status={status}
                        options={{ imageSrc: answer.value }}
                        onClick={() => {
                          if (
                            demoCase &&
                            demoCase.case === 1 &&
                            demoCase.step === 11 &&
                            index !== 0
                          ) {
                            return null;
                          } else if (
                            demoCase &&
                            demoCase.case === 2 &&
                            demoCase.step === 13 &&
                            index !== 1
                          ) {
                            return null;
                          } else {
                            handleAnswer();
                          }
                        }}
                        disabled={!!correctAnswer}
                      />
                    </motion.div>
                  );

                  if (
                    demoCase &&
                    demoCase.case === 1 &&
                    demoCase.step === 11 &&
                    index === 0
                  ) {
                    return (
                      <Highlight key={`answer-image-${index}`}>
                        {answerImage}
                      </Highlight>
                    );
                  } else if (
                    demoCase &&
                    demoCase.case === 2 &&
                    demoCase.step === 13 &&
                    index === 1
                  ) {
                    return (
                      <Highlight key={`answer-${index}`}>
                        {answerImage}
                      </Highlight>
                    );
                  }

                  return answerImage;
                }
              })
              // eslint-disable-next-line @typescript-eslint/naming-convention
              .filter((_, index) => {
                if (!skillResult.hide_answer_indexes) {
                  return true;
                }
                return !skillResult.hide_answer_indexes.includes(index);
              })}
          </div>
        </GameplayLayout>
      </Background>
      <AnimatePresence>
        {showGenie && (
          <motion.div
            variants={bonusResultContainerVariants}
            initial={"hidden"}
            animate={"visible"}
            exit={"hidden"}
            className="modal-presence-wrapper"
          >
            <Modal
              show={showGenie}
              setShow={setShowGenie}
              hideCloseButton
              defaultAnimations={false}
              disableClickOutside
            >
              <GenieModal
                introText="I don’t want to brag but
                I can't help it... This is a piece of cake for me! My guess would be:"
                answer={question?.answers.find(
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  (_, index) => index === skillResult.correct_answer_index!
                )}
                onClick={() => {
                  // if (user) {
                  //   dispatch(
                  //     updateUser({
                  //       ...user,
                  //       character: {
                  //         ...user.character,
                  //         skills: {
                  //           ...user.character.skills,
                  //           lives: user.character.skills.lives - 1,
                  //         },
                  //       },
                  //     } as User)
                  //   );
                  // }
                  if (user && user.character?.skills?.lives <= 1) {
                    game.skills.items.ask_genie.available = false;
                  }

                  if (demoCase && demoCase.case === 1) {
                    dispatch(updateDemoCase({ case: demoCase.case, step: 7 }));
                  } else if (
                    demoCase &&
                    demoCase.case === 2 &&
                    demoCase.step === 18
                  ) {
                    dispatch(updateDemoCase({ case: demoCase.case, step: 19 }));
                  }
                  setShowGenie(false);
                  const gennieStartDate = startGennieDateRef.current;
                  if (gennieStartDate) {
                    addTime(
                      differenceInSeconds(new Date(), gennieStartDate) +
                        ANIMATION_TIME
                    );
                  }
                }}
                genieQuestion="Is this the hardest it can be?"
                genie={game?.genie ?? "male"}
              />
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default GameplayGuessIt;
