import { useEffect, useMemo, useState } from "react";
import { Player } from "../../shared/ui/player";
import { Badge } from "../../shared/ui/badge";
import { PrizeToWin } from "../../shared/ui/prize-to-win";
import { Background } from "../../shared/ui/background";
import { NavBar } from "../../shared/ui/navbar";
import { Button } from "../../shared/ui/button";
import { Modal } from "../../shared/ui/modal";
import { BadgeRewards } from "../../shared/ui/badge-reward";
import "./results.scss";
import { checkEligibleToPlay } from "../../home/feature";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  forceNotificationAction,
  removeNotification,
  selectNotifications,
  selectUser,
  updateNotifications,
  updateUser,
} from "../../auth/data-access/store/authSlice";
import { useNavigate } from "react-router-dom";
import {
  selectDemoCase,
  selectInit,
  selectLanguage,
  selectTranslations,
  updateDemoCase,
} from "../../shared/data-access/store/general/generalSlice";
import CoinsPage from "../../store/ui/store-coins/store-coins";
import { Notification, User } from "../../shared/interfaces/auth.types";
import { checkTranslationKey } from "../../utils/translation";
import { reset } from "../../shared/data-access/store/game/gameSlices";
import { SocketResponse } from "../../shared/data-access/store/game/types/requests";
import { FinishGameEvent } from "../../shared/data-access/store/game/types/socket-events/finish-game";
import { opponentMock } from "../../opponent/feature/opponent";
import { BadgeModal } from "../../badges/ui/achievement-modal";
import Highlight from "../../shared/ui/highlight/highlight";

const ladderMockResultsCase1 = {
  id: "63443505bb9a2109cb080976",
  current_round_index: 3,
  finished: false,
  player: {
    position: 2,
    can_advance: false,
  },
  opponent: {
    position: 1,
    can_advance: false,
  },
};

const finishGameMock = {
  status: "finished",
  finish_date: "2022-10-12 18:27:31",
  points: {
    total: 50,
    for_answers: 50,
  },
  opponent_points: {
    total: 10,
    for_answers: 10,
  },
  prizes: {
    prize: null,
    points: 50,
    lives: 0,
  },
};

const notificationsMock = [
  {
    id: "6346dce39786c8db9b0ac5a0",
    type: "bonusGame",
    target_id: "6346dce39786c8db9b0ac59e",
    title: {
      en: "You just unlocked a bonus game!",
      fr: "Vous venez de déverrouiller un jeu bonus!",
      ar: "You just unlocked a bonus game!",
    },
    text: {
      title: {
        en: "Fastest Finger",
        fr: "Fastest Finger",
        ar: "Fastest Finger",
      },
      description: {
        en: "Mystery challenge!",
        fr: "Mystery challenge!",
        ar: "Mystery challenge!",
      },
      hint: {
        en: "Answer faster than your opponent to win extra diamonds & lives!",
        fr: "Answer faster than your opponent to win extra diamonds & lives!",
        ar: "Answer faster than your opponent to win extra diamonds & lives!",
      },
    },
    button_text: {
      en: "Play now!",
      fr: "Play now!",
      ar: "Play now!",
    },
    button_action: "/home",
  },
  {
    id: "6346e0889786c8db9b0ac5a2",
    type: "achievement",
    target_id: "634030553922dc23210422a2",
    title: {
      en: "New notification achievement",
      fr: "Nouvelle notification achievement",
      ar: "New notification achievement",
    },
    image:
      "https://api.battleup.dev1.beecoded.ro/storage/achievements/first-crack.png",
    text: {
      title: {
        en: "First Crack",
        fr: "First Crack",
        ar: "First Crack",
      },
      description: {
        en: "Play your first challenge",
        fr: "Play your first challenge",
        ar: "Play your first challenge",
      },
    },
    button_text: {
      en: "Continue",
      fr: "Continue",
      ar: "Continue",
    },
    button_action: "/home",
  },
];

const notificationsLevelUpMock = [
  {
    id: "634d40a7e14915682c09b0e2",
    type: "levelUp",
    target_id: "",
    title: {
      en: "New notification levelUp",
      fr: "Nouvelle notification levelUp",
      ar: "New notification levelUp",
    },
    text: {
      new_level: 2,
      current_diamonds: 750,
      amount_lives_awarded: 2,
      level_up: {
        en: "Level Up!",
        fr: "Level Up!",
        ar: "Level Up!",
      },
      diamonds: {
        en: "DIAMONDS",
        fr: "DIAMONDS",
        ar: "DIAMONDS",
      },
      awesome: {
        en: "Awesome!",
        fr: "Awesome!",
        ar: "Awesome!",
      },
      award_lives: {
        en: "Get 2 x {heart} as a gift!",
        fr: "Get 2 x {heart} as a gift!",
        ar: "Get 2 x {heart} as a gift!",
      },
      next_threshold: {
        en: "Continue with the great momentum and gain 1600 diamonds to reach the next level.",
        fr: "Continue with the great momentum and gain 1600 diamonds to reach the next level.",
        ar: "Continue with the great momentum and gain 1600 diamonds to reach the next level.",
      },
    },
    button_text: {
      en: "Continue",
      fr: "Continue",
      ar: "Continue",
    },
    button_action: "/home",
  },
];

function Results() {
  const demoCase = useAppSelector(selectDemoCase);

  if (demoCase && demoCase.case === 2 && demoCase.step === 24) {
    finishGameMock.prizes = {
      lives: 1,
      points: 600,
      prize: null,
    };
  }

  const user = useAppSelector(selectUser);
  const notifications = useAppSelector(selectNotifications);
  const [notificationsCopy, setNotificationsCopy] =
    useState<null | Array<Notification>>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectLanguage);
  const init = useAppSelector(selectInit);
  const [showModalCoins, setShowModalCoins] = useState(false);
  const [achievementsReward, setAchievementReward] = useState<null | Array<
    Extract<Notification, { type: "achievement" }>
  >>(null);
  const translations = useAppSelector(selectTranslations);
  const [achievementModal, setAchievementModal] = useState<{
    show: boolean;
    achievementId: null | string;
  }>({
    show: false,
    achievementId: null,
  });
  const [notificationsCopied, setNotificationsCopied] = useState(false);

  const finishGameResponse:
    | SocketResponse<"finish_game", FinishGameEvent>
    // @ts-ignore
    | undefined = { call: "finish_game", data: finishGameMock };

  const prizes = finishGameResponse?.data?.prizes;
  const ladder = ladderMockResultsCase1;
  const opponent = {
    call: "get_opponent",
    // @ts-ignore
    opponent: opponentMock,
  };

  enum status {
    Won = "won",
    Lost = "lost",
    Tie = "tie",
  }

  useEffect(() => {
    if (demoCase && demoCase.case === 1 && demoCase.step === 13) {
      if (user) {
        dispatch(
          updateUser({
            ...user,
            character: {
              ...user.character,
              points: 100,
              // skills: {
              //   ...user.character.skills,
              //   lives: 1,
              // },
            },
          } as User)
        );
      }
      dispatch(updateNotifications(notificationsMock));
    } else if (demoCase && demoCase.case === 2 && demoCase.step === 24) {
      if (user) {
        dispatch(
          updateUser({
            ...user,
            character: {
              ...user.character,
              points: 750,
              skills: {
                ...user.character.skills,
                lives: 1,
              },
            },
          } as User)
        );
      }
      dispatch(updateNotifications(notificationsLevelUpMock));
    }
  }, []);

  useEffect(() => {
    if (!ladder) {
      navigate("/");
    } else {
      setNotificationsCopy(notifications);
    }
  }, [navigate]);

  useEffect(() => {
    if (notifications && !notificationsCopied) {
      setNotificationsCopy(notifications);
      setNotificationsCopied(true);
    }
  }, [notifications]);

  useEffect(() => {
    if (notificationsCopy) {
      const achievementNotifications = notificationsCopy.filter(
        (item) => item.type === "achievement"
      );
      if (achievementNotifications) {
        setAchievementReward(
          achievementNotifications as Array<
            Extract<Notification, { type: "achievement" }>
          >
        );
      }
    }
  }, [notificationsCopy]);

  const currentGameStatus: status | undefined = useMemo(() => {
    if (ladder) {
      const playerWinScore =
        ladder.player.position + (ladder.player.can_advance ? 1 : 0);
      const opponentWinScore =
        ladder.opponent.position + (ladder.opponent.can_advance ? 1 : 0);

      return playerWinScore === opponentWinScore
        ? status.Tie
        : playerWinScore > opponentWinScore
        ? status.Won
        : status.Lost;
    }
  }, [ladder]);

  const handlePlay = () => {
    if (demoCase && demoCase.case === 1 && demoCase.step === 13) {
      return null;
    } else if (demoCase && demoCase.case === 2 && demoCase.step === 24) {
      dispatch(forceNotificationAction(true));
      return null;
    }
    dispatch(reset());
    navigate("/");
  };

  // const handleBuyCredits = () => {
  //   setShowModalCoins(true);
  // };

  const closeAchievementModal = () => {
    setAchievementModal({ show: false, achievementId: null });
  };

  const { disabled, textButton, description } = checkEligibleToPlay({
    eligible: user?.eligibility?.eligible || false,
    canPlay: user?.eligibility?.canPlay || false,
    disableBuyCredits: init?.features?.disableBuyCredits || false,
    texts: {
      btnPlay: checkTranslationKey(
        translations[lang]?.Play_again,
        "Play again!"
      ),
      btnPlayBuy: (
        <>
          {checkTranslationKey(
            translations[lang]?.Play_again_for,
            "Play again for"
          )}{" "}
          <img src="/img/icns/coin.svg" alt="" />
        </>
      ),
      descriptionFinishedAll: checkTranslationKey(
        translations[lang]?.You_played_all_the_games_of_the_day,
        "You played all the games of the day! See you again tomorrow!"
      ),
      descriptionBuy: checkTranslationKey(
        translations[lang]?.You_cannot_play_right_now_Check_your_balance,
        "You cannot play right now. Check your balance and try again."
      ),
    },
  });

  const selectedAchievement = achievementsReward?.find(
    (achievement) => achievement.id === achievementModal.achievementId
  );

  return (
    <div id="results">
      <Background type="geography">
        <NavBar hasStats hasAvatar />
        <div className="content-results">
          <div className="title-results">
            {/* can use ribbon-red.svg as well */}
            {currentGameStatus === status.Won ? (
              <img src="/img/icns/ribbon-yellow.svg" alt="" />
            ) : (
              <img src="/img/icns/ribbon-red.svg" alt="" />
            )}

            <p>
              {currentGameStatus === status.Won
                ? checkTranslationKey(
                    translations[lang]?.CONGRATULATIONS,
                    "CONGRATULATIONS!"
                  )
                : currentGameStatus === status.Lost
                ? checkTranslationKey(
                    translations[lang]?.NEXT_TIME_BETTER,
                    "NEXT TIME BETTER!"
                  )
                : checkTranslationKey(
                    translations[lang]?.NEXT_TIME_BETTER,
                    "NEXT TIME BETTER!"
                  )}
            </p>
          </div>
          <div className="players">
            <div className="player-wrapper">
              <Player
                winner={currentGameStatus === status.Won}
                bottom={
                  <Badge type="primary">
                    {user?.character?.level?.current}
                  </Badge>
                }
                avatar={user?.character?.avatar || ""}
                name={user?.character?.nickname || ""}
              ></Player>
            </div>
            <div className="player-wrapper">
              <Player
                winner={currentGameStatus === status.Lost}
                bottom={
                  <Badge type="primary">{opponent?.opponent?.level}</Badge>
                }
                avatar={opponent?.opponent?.avatar || ""}
                name={opponent?.opponent?.nickname}
              ></Player>
            </div>
          </div>
          <p className="title-rewards">
            {checkTranslationKey(
              translations[lang]?.You_ended_up_with,
              "You ended up with:"
            )}
          </p>
          <div className="rewards">
            <BadgeRewards />
            <div className="list-rewards">
              {prizes && (
                <>
                  {prizes.points > 0 && (
                    <div className="item">
                      <div className="img">
                        <img src="/img/icns/diamonds.svg" alt="" />
                      </div>
                      <p>{prizes.points}</p>
                    </div>
                  )}
                  {prizes.lives > 0 && (
                    <div className="item">
                      <div className="img">
                        <img src="/img/icns/lives.svg" alt="" />
                      </div>
                      <p>{prizes.lives}</p>
                    </div>
                  )}

                  {prizes.prize && (
                    <div className="item">
                      <div className="img">
                        <img src={prizes.prize.image} alt="" />
                      </div>
                      <p>{prizes.prize.name[lang]}</p>
                    </div>
                  )}
                </>
              )}

              {achievementsReward?.map((achievementReward) => {
                const achievement = (
                  <div className="item" key={achievementReward.id}>
                    <div
                      className="img"
                      onClick={() =>
                        setAchievementModal({
                          show: true,
                          achievementId: achievementReward.id,
                        })
                      }
                    >
                      <img
                        src={achievementReward.image}
                        alt={achievementReward.text?.title[lang] || ""}
                      />
                      <div className="information">
                        <img
                          src="/img/icns/information-icon.svg"
                          alt={achievementReward.text?.description[lang]}
                        />
                      </div>
                    </div>
                    <p>{achievementReward.text?.title[lang]}</p>
                  </div>
                );

                if (demoCase && demoCase.case === 1 && demoCase.step === 13) {
                  return (
                    <Highlight key={achievementReward.id}>
                      {achievement}
                    </Highlight>
                  );
                }

                return achievement;
              })}
            </div>
          </div>
          <div className="btn-container">
            {demoCase && demoCase.case === 2 && demoCase.step === 24 ? (
              <Highlight>
                <Button
                  buttonType="success"
                  disabled={disabled}
                  onClick={handlePlay}
                >
                  {textButton}
                </Button>
              </Highlight>
            ) : (
              <Button
                buttonType="success"
                disabled={disabled}
                onClick={handlePlay}
              >
                {textButton}
              </Button>
            )}

            {description && <p>{description}</p>}
          </div>
        </div>

        <Modal
          setShow={setShowModalCoins}
          show={showModalCoins}
          hideCloseButton
        >
          <div className="modal-buy-coins">
            <CoinsPage
              title={checkTranslationKey(
                translations[lang]?.You_run_out_of_coins,
                "You run out of coins!"
              )}
              subtitle={checkTranslationKey(
                translations[lang]?.Buy_more_coins_now_and_keep_on_playing,
                "Buy more coins now and keep on playing!"
              )}
              confirmCallback={() => setShowModalCoins(false)}
            />
          </div>
        </Modal>
      </Background>
      <div className="prizes-win">
        <PrizeToWin />
      </div>
      {selectedAchievement && (
        <Modal
          show={achievementModal.show}
          setShow={closeAchievementModal}
          hideCloseButton
          disableClickOutside={true}
        >
          <BadgeModal
            badge={selectedAchievement.image}
            title={selectedAchievement.text?.title[lang]}
            buttonText={selectedAchievement.button_text[lang]}
            buttonClick={() => {
              closeAchievementModal();
              dispatch(removeNotification({ id: selectedAchievement.id }));
              if (demoCase && demoCase.case === 1 && demoCase.step === 13) {
                dispatch(updateDemoCase({ case: demoCase.case, step: 14 }));
                navigate("/home");
              }
            }}
          >
            {selectedAchievement.text?.description[lang]}
          </BadgeModal>
        </Modal>
      )}
    </div>
  );
}

export default Results;
