import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  forceNotificationAction,
  removeNotification,
  selectForceNotification,
  selectNotifications,
} from "../../auth/data-access/store/authSlice";
import { LevelUp } from "../../shared/ui/modal-notifications/level-up";
import { Notification } from "../../shared/interfaces/auth.types";
import {
  selectDemoCase,
  selectLanguage,
  updateDemoCase,
} from "../../shared/data-access/store/general/generalSlice";
import { BadgeModal } from "../../badges/ui/achievement-modal";
import { Modal } from "../../shared/ui/modal";
import PlayBonusGame from "../../shared/ui/modal-notifications/play-bonus-game/play-bonus-game";
import { useLocation, useNavigate } from "react-router-dom";

export const Notifications = () => {
  const demoCase = useAppSelector(selectDemoCase);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const [showNotification, setShowNotification] = useState(false);
  const lang = useAppSelector(selectLanguage);
  const { pathname } = useLocation();
  const forceNotification = useAppSelector(selectForceNotification);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (
      (notifications && notifications.length && !checkIfInGame(pathname)) ||
      forceNotification
    ) {
      timeout = setTimeout(() => {
        if (forceNotification) {
          dispatch(forceNotificationAction(false));
        }
        setShowNotification(true);
      }, 400);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [notifications, pathname, forceNotification]);

  const checkIfInGame = (path: string) => {
    return (
      path.includes("opponent") ||
      path.includes("ladder") ||
      path.includes("gameplay") ||
      path.includes("category") ||
      path.includes("results")
    );
  };

  const renderNotificationByType = (notification: Notification) => {
    switch (notification.type) {
      case "levelUp":
        return (
          <LevelUp
            modalOpen={showNotification}
            setModalOpen={setShowNotification}
            buttonClick={() => {
              dispatch(removeNotification({ id: notification.id }));
              if (demoCase && demoCase.case === 2 && demoCase.step === 24) {
                dispatch(updateDemoCase(null));
                // @ts-ignore
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.origin;
                // window.location.reload(true);
                // navigate("/");
              }
            }}
            level={notification.text?.new_level}
            title={notification.text?.level_up[lang]}
            subtitle={notification.text?.awesome[lang]}
            lives={notification.text?.amount_lives_awarded}
            livesText={notification.text?.award_lives[lang]}
            nextLevelDescription={notification.text?.next_threshold[lang]}
            points={notification.text?.current_diamonds}
            buttonText={notification.button_text[lang]}
          />
        );
      case "bonusGame": {
        return (
          <PlayBonusGame
            modalOpen={showNotification}
            setModalOpen={setShowNotification}
            title={notification.text?.description[lang]}
            subtitle={notification.text?.title[lang]}
            description={notification.text?.hint[lang]}
            buttonText={notification.button_text[lang]}
            buttonClick={() => {
              setShowNotification(false);
              dispatch(removeNotification({ id: notification.id }));
              navigate(`/gameplay/bonus?id=${notification.target_id}`);
            }}
          ></PlayBonusGame>
        );
      }
      case "achievement": {
        return (
          <Modal
            show={showNotification}
            setShow={setShowNotification}
            hideCloseButton
          >
            <BadgeModal
              badge={notification.image}
              title={notification.text?.title[lang]}
              buttonText={notification.button_text[lang]}
              buttonClick={() => {
                setShowNotification(false);
                dispatch(removeNotification({ id: notification.id }));
              }}
            >
              {notification.text?.description[lang]}
            </BadgeModal>
          </Modal>
        );
      }
    }
  };

  return (
    <>
      {notifications && notifications.length > 0
        ? renderNotificationByType(notifications[0])
        : null}
    </>
  );
};

export default Notifications;
