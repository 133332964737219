import { useState } from "react";
import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { TabSliding, Tab } from "../../../shared/ui/tab-sliding";
import { Title } from "../../../shared/ui/title";

import "./store-view.scss";
import { OffersPage } from "../../ui/store-offers";
import { CoinsPage } from "../../ui/store-coins";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import Highlight from "../../../shared/ui/highlight/highlight";

export function StoreView() {
  const [tab, setTab] = useState<Tab>("offers");
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div id="store">
      <Background type="movies">
        <NavBar hasAvatar></NavBar>
        <Title>{checkTranslationKey(translations[lang]?.Store, "Store")}</Title>
        <div className="tab-area">
          <Highlight>
            <TabSliding tabChange={setTab}></TabSliding>
          </Highlight>
        </div>

        {tab === "offers" ? <OffersPage /> : <CoinsPage />}
      </Background>
    </div>
  );
}

export default StoreView;
