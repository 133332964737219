import InfiniteScroll from "react-infinite-scroller";
import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import { LeaderStep } from "../../ui/leader-step";
import "./leaderboard-view.scss";
import { Leaderboard } from "../../../shared/interfaces/auth.types";
import { useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../../auth/data-access/store/authSlice";
import { motion, Variants } from "framer-motion";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

const leaderboardListVariants: Variants = {
  hidden: {
    y: -10,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.05 * custom,
    },
  }),
};

export function LeaderboardView() {
  const user = useAppSelector(selectUser);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const leaderboardData = {
    items: [
      {
        msisdn: null,
        points: 40540,
        id: "63443500a5e76374d80d6d0e",
        rank: 1,
        nickname: "User21433898",
        avatar:
          "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Female-08.png",
      },
      {
        msisdn: null,
        points: 39650,
        id: "634030c784a4fd91d20b5ffa",
        rank: 2,
        nickname: "Player17348803",
        avatar:
          "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Female-07.png",
      },
      {
        msisdn: "10000000084",
        points: 13100,
        id: "63440fce01b0ec108800ca23",
        rank: 3,
        nickname: "User84568924",
        avatar:
          "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Female-09.png",
      },
      {
        msisdn: null,
        points: 6600,
        id: "63440ff401b0ec108800ca26",
        rank: 4,
        nickname: "Player38011229",
        avatar:
          "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Female-09.png",
      },
      {
        msisdn: "10000000008",
        points: 3350,
        id: "63403d96c8bc94dde50b1e31",
        rank: 5,
        nickname: "User22421972",
        avatar:
          "https://api.battleup.dev1.beecoded.ro/storage/avatars/Avatar-Male-01.png",
      },
    ],
    notifications: true,
  };

  const renderLeaderboardItems = (leaderboardItems: Leaderboard["items"]) => {
    return leaderboardItems?.map((item, index) => {
      let sticky: "top" | "bottom" | undefined;
      let type: "success" | "gold" | undefined;
      if (item.rank === 1) {
        sticky = "top";
        type = "gold";
      }

      if (user && user.character?.id === item.id) {
        type = "success";
      }
      return (
        <motion.li
          key={item.id}
          variants={leaderboardListVariants}
          initial="hidden"
          animate="visible"
          // @ts-ignore
          custom={index}
        >
          <LeaderStep
            type={type}
            avatar={item.avatar}
            numberDiamonds={item.points}
            position={item.rank}
            sticky={sticky}
          ></LeaderStep>
        </motion.li>
      );
    });
  };

  return (
    <>
      <Background type="geography">
        <NavBar></NavBar>
        <Title>
          {checkTranslationKey(translations[lang]?.Leaderboard, "Leaderboard")}
        </Title>
        <div className="leaderboard-container">
          <InfiniteScroll
            element="ul"
            className="leaderboard-scrolling"
            pageStart={0}
            loadMore={() => false}
            hasMore={false}
            useWindow={false}
            initialLoad={false}
            threshold={10}
          >
            {leaderboardData
              ? renderLeaderboardItems(leaderboardData.items)
              : null}
          </InfiniteScroll>

          <LeaderStep
            avatar={user?.character?.avatar || ""}
            numberDiamonds={user?.character?.points || 0}
            position={user?.character?.position || 0}
            type="success"
            sticky="bottom"
          ></LeaderStep>
        </div>
      </Background>
    </>
  );
}

export default LeaderboardView;
