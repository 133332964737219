const BASE_PATH = "/sounds";

const SoundsSources = {
  ambient_1: new Audio(`${BASE_PATH}/Happy.mp3`),
  ambient_2: new Audio(`${BASE_PATH}/Family_Trip_Short.wav`),
  ambient_3: new Audio(`${BASE_PATH}/Ambient_3_cut.mp3`),
  ambient_4: new Audio(`${BASE_PATH}/Ambient_Background.wav`),
  ambient_5: new Audio(`${BASE_PATH}/Timer_Clock_Ticking.mp3`),
  vfx_play: new Audio(`${BASE_PATH}/Game_Success.mp3`),
  vfx_swipe: new Audio(`${BASE_PATH}/Christmas_Reveal.wav`),
  vfx_win: new Audio(`${BASE_PATH}/Game_Win_Horns.wav`),
  vfx_lose: new Audio(`${BASE_PATH}/analog_game_descending_lost.wav`),
  vfx_clock: new Audio(`${BASE_PATH}/Timer_Clock_Ticking.mp3`),
  vfx_hide: new Audio(`${BASE_PATH}/Whoosh_Game_Swipe.wav`),
  vfx_skip: new Audio(`${BASE_PATH}/Fast_Whoosh.wav`),
  vfx_genie: new Audio(`${BASE_PATH}/Genie_Magic.mp3`),
  vfx_correct: new Audio(`${BASE_PATH}/Correct.wav`),
  vfx_wrong: new Audio(`${BASE_PATH}/Wrong.mp3`),
  vfx_angelic: new Audio(`${BASE_PATH}/Magic_Wand_With_Angels.mp3`),
  vfx_cheerful: new Audio(`${BASE_PATH}/Small_Success.mp3`),
  vfx_awesome: new Audio(`${BASE_PATH}/Victory.mp3`),
  vfx_well_done: new Audio(`${BASE_PATH}/Well_done.mp3`),
  vfx_wow: new Audio(`${BASE_PATH}/Amazing_Fantasy_Spell.wav`),
  vfx_climb_up: new Audio(`${BASE_PATH}/Scoring_a_Point.mp3`),
  vfx_climb_down: new Audio(`${BASE_PATH}/analog_game_descending_lost.wav`),
  vfx_life: new Audio(`${BASE_PATH}/Revive_Life.wav`),
};

const SoundManager = {
  ambientSounds: {
    home: SoundsSources.ambient_1,
    opponent: SoundsSources.ambient_2,
    tutorial: SoundsSources.ambient_3,
    game: SoundsSources.ambient_4,
    tick: SoundsSources.ambient_5,
  },
  vfxSounds: {
    play: SoundsSources.vfx_play,
    swipe: SoundsSources.vfx_swipe,
    win: SoundsSources.vfx_win,
    lose: SoundsSources.vfx_lose,
    clock: SoundsSources.vfx_clock,
    hide: SoundsSources.vfx_hide,
    skip: SoundsSources.vfx_skip,
    genie: SoundsSources.vfx_genie,
    correct: SoundsSources.vfx_correct,
    wrong: SoundsSources.vfx_wrong,
    angelic: SoundsSources.vfx_angelic,
    cheerful: SoundsSources.vfx_cheerful,
    awesome: SoundsSources.vfx_awesome,
    well_done: SoundsSources.vfx_well_done,
    wow: SoundsSources.vfx_wow,
    climb_up: SoundsSources.vfx_climb_up,
    climb_down: SoundsSources.vfx_climb_down,
    life: SoundsSources.vfx_life,
  },
};

export default SoundManager;
