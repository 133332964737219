import { ComponentProps } from "react";
import "./avatar.scss";

export interface AvatarProps extends ComponentProps<"div"> {
  avatarType?: "primary" | "success" | "defeat";
  bottom?: Array<JSX.Element> | JSX.Element | string;
  left?: Array<JSX.Element> | JSX.Element | string;
  right?: Array<JSX.Element> | JSX.Element | string;
  image: string;
}

export function Avatar({
  avatarType,
  bottom,
  left,
  right,
  image,
  ...props
}: AvatarProps) {
  return (
    <div
      {...props}
      className={`avatar avatar-${avatarType || "primary"} ${
        props.className ?? ""
      }`}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      {left ? <div className="slot-left">{left}</div> : null}
      {right ? <div className="slot-right">{right}</div> : null}
      {bottom ? <div className="slot-bottom">{bottom}</div> : null}
    </div>
  );
}

export default Avatar;
