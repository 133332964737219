import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import Onboarding from "./onboarding/feature/onboarding";
import Intro from "./intro/feature/intro";
import { OpponentShell } from "./opponent/feature/opponent-shell";

import { Ladder } from "./ladder/feature";

import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import {
  clearMessage,
  selectMessage,
  setMessage,
} from "./shared/data-access/store/message/messageSlice";
import FourZeroFour from "./404/ui/404";
import { GameplayShell } from "./gameplay/feature/gameplay-shell";
import { ProfileShell } from "./profile/feature/profile-shell";
import { StoreShell } from "./store/feature/store-shell";
import { LeaderboardShell } from "./leaderboard/feature/leaderboard-shell";
import { PrizesShell } from "./prizes/feature/prizes-shell";
import { BadgesShell } from "./badges/feature/badges-shell";
import { FaqShell } from "./faq/feature/faq-shell";
import { CategoryShell } from "./category/feature/category-shell";
import Results from "./results/feature/results";
import { useGame } from "./shared/data-access/store/game/hooks/use-game";
// import RouteLoaded from "./shared/data-access/route-loaded";
import Notifications from "./notifications/feature/notifications";
import Home from "./home/feature/home";

import { Loader } from "./shared/ui/loader";
import useLocals from "./utils/hooks/use-locals";
import { addSeconds, differenceInMilliseconds } from "date-fns";
import {
  selectIsLoadingPage,
  setIsLoadingPage,
} from "./shared/data-access/store/ui/uiSlice";
import { useSelector } from "react-redux";
import { selectDemoCase } from "./shared/data-access/store/general/generalSlice";

/**
 * This is where all the routing paths are set up.
 * @category Router
 */

const SECONDS_PER_QUESTION = 30;
const NUMBER_OF_ROUNDS = 10;
const INDULGENCE = 3;
export const gameRoutes = ["opponent", "ladder", "gameplay", "category"];

function App() {
  const dispatch = useAppDispatch();
  const message = useAppSelector(selectMessage);
  const { getValue, setValue, reset: resetLocals } = useLocals();
  const isLoadingPage = useSelector(selectIsLoadingPage);
  const demoCase = useAppSelector(selectDemoCase);

  useEffect(() => {
    if (message && message.content) {
      if (message.type === "error") {
        toast.error(message.content, {
          onClose: () => dispatch(clearMessage()),
        });
      } else if (message.type === "success") {
        toast.success(message.content, {
          onClose: () => dispatch(clearMessage()),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const { send, gameState, finish, resetGame } = useGame();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathnameRef = useRef(pathname);

  useEffect(() => {
    pathnameRef.current = pathname;

    if (pathname !== "/" && !demoCase) {
      navigate("/");
    }
  }, [pathname]);

  const onExpiration = async () => {
    resetLocals();
    dispatch(setIsLoadingPage(true));
    await finish(true);
    resetGame();

    if (pathnameRef.current.includes("opponent")) {
      dispatch(setIsLoadingPage(false));
      send(true);
      navigate("/opponent");
    } else {
      dispatch(
        setMessage({
          type: "error",
          content: "Last game expired, try again",
        })
      );
      dispatch(setIsLoadingPage(false));
      navigate("/");
    }
  };

  const handleFinishedGame = async () => {
    resetLocals();
    dispatch(setIsLoadingPage(true));
    await finish(true);
    resetGame();
    dispatch(setIsLoadingPage(false));
    send(true);
    navigate("/opponent");
  };

  useEffect(() => {
    if (gameState.game?.id) {
      if (!gameState.game?.finished) {
        if (gameState.game.id !== getValue("currentGameId")) {
          if (
            getValue("triggerNewGame") &&
            gameState.game.current_round_index > 0
          ) {
            handleFinishedGame();
          } else {
            resetLocals();
            setValue("currentGameId", gameState.game.id);

            const expiration = addSeconds(
              new Date(),
              SECONDS_PER_QUESTION * NUMBER_OF_ROUNDS * INDULGENCE
            ).toISOString();

            setValue("currentGameExpTs", expiration);

            const timeout = setTimeout(() => {
              onExpiration();
            }, differenceInMilliseconds(new Date(expiration), new Date()));
            return () => {
              clearTimeout(timeout);
            };
          }
        } else {
          if (getValue("triggerNewGame")) {
            handleFinishedGame();
          } else {
            const expLocals = getValue("currentGameExpTs");

            if (expLocals) {
              if (new Date(expLocals) <= new Date()) {
                onExpiration();
              } else {
                const timeout = setTimeout(() => {
                  onExpiration();
                }, differenceInMilliseconds(new Date(expLocals), new Date()));
                return () => {
                  clearTimeout(timeout);
                };
              }
            }
          }
        }
      } else {
        handleFinishedGame();
      }
    }
  }, [gameState.game?.id]);

  if (isLoadingPage) {
    return <Loader />;
  }

  return (
    <>
      <Notifications />
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/home" element={<Home />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/opponent/*" element={<OpponentShell />} />
        <Route path="/ladder" element={<Ladder />} />
        <Route path="/category/*" element={<CategoryShell />} />
        <Route path="/gameplay/*" element={<GameplayShell />} />
        <Route path="/profile/*" element={<ProfileShell />} />
        <Route path="/store/*" element={<StoreShell />} />
        <Route path="/leaderboard/*" element={<LeaderboardShell />} />
        <Route path="/prizes/*" element={<PrizesShell />} />
        <Route path="/achievements/*" element={<BadgesShell />} />
        <Route path="/faq/*" element={<FaqShell />} />
        <Route path="/results" element={<Results />} />
        <Route path="*" element={<FourZeroFour />}></Route>
      </Routes>
    </>
  );
}

export default App;
