//Case 1 mocks

export const startRound1Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 0,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "What is the name of the preserved bodies in ancient Egypt?",
      fr: "What is the name of the preserved bodies in ancient Egypt?",
      ar: "What is the name of the preserved bodies in ancient Egypt?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "Grannies",
          fr: "Grannies",
          ar: "Grannies",
        },
      },
      {
        type: "text",
        value: {
          en: "Mummies",
          fr: "Mummies",
          ar: "Mummies",
        },
      },
      {
        type: "text",
        value: {
          en: "Aunties",
          fr: "Aunties",
          ar: "Aunties",
        },
      },
      {
        type: "text",
        value: {
          en: "Daddies",
          fr: "Daddies",
          ar: "Daddies",
        },
      },
    ],
    timer: 30,
  },
};

export const startRound2Mock = {
  id: "6346e0988ad6f3b495015c71",
  current_round_index: 1,
  question: {
    id: "6346c2a0fb807ae8080ea062",
    content: {
      en: '"Once in a ______ moon"',
    },
    answers: [
      {
        type: "text",
        value: {
          en: "Blue",
        },
      },
      {
        type: "text",
        value: {
          en: "Red",
        },
      },
      {
        type: "text",
        value: {
          en: "Green",
        },
      },
      {
        type: "text",
        value: {
          en: "Pink",
        },
      },
    ],
    timer: 30,
    type_id: "634030553922dc2321042293",
    category_id: "634030553922dc2321042296",
  },
};

export const startRound3Mock = {
  id: "634801cf6aebcaea180a2025",
  current_round_index: 3,
  question: {
    id: "634030553922dc23210422cb",
    category_id: "634030553922dc2321042296",
    type_id: "634030553922dc2321042294",
    content: {
      en: "Which tech entrepreneur named his son X Æ A-12?",
      fr: "Which tech entrepreneur named his son X Æ A-12?",
      ar: "Which tech entrepreneur named his son X Æ A-12?",
    },
    answers: [
      {
        type: "image",
        value:
          "https://api.battleup.dev1.beecoded.ro/storage/demo/game-questions/633acfb544cf81d39a064424/answer0_original.jpg",
      },
      {
        type: "image",
        value:
          "https://api.battleup.dev1.beecoded.ro/storage/demo/game-questions/633acfb544cf81d39a064424/answer1_original.jpg",
      },
    ],
    timer: 30,
  },
};

export const answer1Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 0,
  round: {
    answer_index: 1,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 1,
  },
  opponent_round: {
    answer_index: 1,
    answer_timer: 25,
    correct: true,
  },
  current_round_index: 1,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const answer2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 0,
  round: {
    answer_index: 0,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 0,
  },
  opponent_round: {
    answer_index: 1,
    answer_timer: 25,
    correct: true,
  },
  current_round_index: 2,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const answer3Mock = {
  id: "634801cf6aebcaea180a2025",
  updated_round_index: 3,
  round: {
    answer_index: 0,
    answer_timer: 12,
    correct: false,
    correct_answer_index: 1,
  },
  current_round_index: 4,
  finished: true,
  skills: {
    lives: 26,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

// Case 2 mocks

export const startRound1Case2Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 1,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "What name is given to a large collection of information stored in a computer?",
      fr: "What name is given to a large collection of information stored in a computer?",
      ar: "What name is given to a large collection of information stored in a computer?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "Airbase",
          fr: "Airbase",
          ar: "Airbase",
        },
      },
      {
        type: "text",
        value: {
          en: "Counterbase",
          fr: "Counterbase",
          ar: "Counterbase",
        },
      },
      {
        type: "text",
        value: {
          en: "Database",
          fr: "Database",
          ar: "Database",
        },
      },
      {
        type: "text",
        value: {
          en: "Wheelbase",
          fr: "Wheelbase",
          ar: "Wheelbase",
        },
      },
    ],
    timer: 30,
  },
};

export const answer1Case2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 0,
  round: {
    answer_index: 2,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 2,
  },
  opponent_round: {
    answer_index: 2,
    answer_timer: 25,
    correct: true,
  },
  current_round_index: 1,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const startRound2Case2Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 2,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "Who wrote the book The Lord of the Rings?",
      fr: "Who wrote the book The Lord of the Rings?",
      ar: "Who wrote the book The Lord of the Rings?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "J.R.R. Tolkien",
          fr: "J.R.R. Tolkien",
          ar: "J.R.R. Tolkien",
        },
      },
      {
        type: "text",
        value: {
          en: "C.S. Lewis",
          fr: "C.S. Lewis",
          ar: "C.S. Lewis",
        },
      },
      {
        type: "text",
        value: {
          en: "J.K. Rowling",
          fr: "J.K. Rowling",
          ar: "J.K. Rowling",
        },
      },
      {
        type: "text",
        value: {
          en: "Veronica Roth",
          fr: "Veronica Roth",
          ar: "Veronica Roth",
        },
      },
    ],
    timer: 30,
  },
};

export const answer2Case2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 0,
  round: {
    answer_index: 0,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 0,
  },
  opponent_round: {
    answer_index: 0,
    answer_timer: 25,
    correct: true,
  },
  current_round_index: 2,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const startRound3Case2Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 3,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "Sushi is originally from ______",
      fr: "Sushi is originally from ______",
      ar: "Sushi is originally from ______",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "China",
          fr: "China",
          ar: "China",
        },
      },
      {
        type: "text",
        value: {
          en: "USA",
          fr: "USA",
          ar: "USA",
        },
      },
      {
        type: "text",
        value: {
          en: "Vietnam",
          fr: "Vietnam",
          ar: "Vietnam",
        },
      },
      {
        type: "text",
        value: {
          en: "Japan",
          fr: "Japan",
          ar: "Japan",
        },
      },
    ],
    timer: 30,
  },
};

export const answer3Case2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 3,
  round: {
    answer_index: 3,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 3,
  },
  opponent_round: {
    answer_index: 3,
    answer_timer: 25,
    correct: true,
  },
  current_round_index: 3,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const startRound4Case2Mock = {
  id: "634801cf6aebcaea180a2025",
  current_round_index: 4,
  question: {
    id: "634030553922dc23210422cb",
    category_id: "634030553922dc2321042296",
    type_id: "634030553922dc2321042294",
    content: {
      en: "Which is the mascot of World Cup 2022?",
      fr: "Which is the mascot of World Cup 2022?",
      ar: "Which is the mascot of World Cup 2022?",
    },
    answers: [
      {
        type: "image",
        value: "/img/mock-question-image-1.png",
      },
      {
        type: "image",
        value: "/img/mock-question-image-2.png",
      },
    ],
    timer: 30,
  },
};

export const answer4Case2Mock = {
  id: "634801cf6aebcaea180a2025",
  updated_round_index: 4,
  round: {
    answer_index: 1,
    answer_timer: 12,
    correct: true,
    correct_answer_index: 1,
  },
  opponent_round: {
    answer_index: 1,
    answer_timer: 25,
    correct: true,
  },
  current_round_index: 5,
  finished: true,
  skills: {
    lives: 26,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const startRound5Case2Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 5,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "Which is the city that hosted Olympic Games in 2020?",
      fr: "Which is the city that hosted Olympic Games in 2020?",
      ar: "Which is the city that hosted Olympic Games in 2020?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "Rio de Janeiro",
          fr: "Rio de Janeiro",
          ar: "Rio de Janeiro",
        },
      },
      {
        type: "text",
        value: {
          en: "London",
          fr: "London",
          ar: "London",
        },
      },
      {
        type: "text",
        value: {
          en: "Tokyo",
          fr: "Tokyo",
          ar: "Tokyo",
        },
      },
      {
        type: "text",
        value: {
          en: "Athens",
          fr: "Athens",
          ar: "Athens",
        },
      },
    ],
    timer: 30,
  },
};

export const startRound5Case2MockSkip = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 5,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "Which is the city that hosts Roland-Garros?",
      fr: "Which is the city that hosts Roland-Garros?",
      ar: "Which is the city that hosts Roland-Garros?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "London",
          fr: "London",
          ar: "London",
        },
      },
      {
        type: "text",
        value: {
          en: "Paris",
          fr: "Paris",
          ar: "Paris",
        },
      },
      {
        type: "text",
        value: {
          en: "Barcelona",
          fr: "Barcelona",
          ar: "Barcelona",
        },
      },
      {
        type: "text",
        value: {
          en: "Zurich",
          fr: "Zurich",
          ar: "Zurich",
        },
      },
    ],
    timer: 30,
  },
};

export const answer5Case2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 5,
  round: {
    answer_index: 1,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 1,
  },
  opponent_round: {
    answer_index: 2,
    answer_timer: 25,
    correct: false,
  },
  current_round_index: 5,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const startRound6Case2Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 6,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "The first AFCON tournament hosted in",
      fr: "The first AFCON tournament hosted in",
      ar: "The first AFCON tournament hosted in",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "1950",
          fr: "1950",
          ar: "1950",
        },
      },
      {
        type: "text",
        value: {
          en: "1957",
          fr: "1957",
          ar: "1957",
        },
      },
      {
        type: "text",
        value: {
          en: "1967",
          fr: "1967",
          ar: "1967",
        },
      },
      {
        type: "text",
        value: {
          en: "1970",
          fr: "1970",
          ar: "1970",
        },
      },
    ],
    timer: 30,
  },
};

export const answer6Case2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 6,
  round: {
    answer_index: 1,
    answer_timer: 29,
    correct: true,
    correct_answer_index: 1,
  },
  opponent_round: {
    answer_index: 2,
    answer_timer: 25,
    correct: false,
  },
  current_round_index: 6,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};

export const startRound7Case2Mock = {
  id: "634565d9e7748290e30f4831",
  current_round_index: 7,
  question: {
    id: "6340306188dcf7549b0890de",
    category_id: "634030553922dc2321042296",
    type_id: "6340306188dcf7549b089082",
    content: {
      en: "What is the length of Somalia's coastline in kilometers?",
      fr: "What is the length of Somalia's coastline in kilometers?",
      ar: "What is the length of Somalia's coastline in kilometers?",
    },
    answers: [
      {
        type: "text",
        value: {
          en: "1111",
          fr: "1111",
          ar: "1111",
        },
      },
      {
        type: "text",
        value: {
          en: "2222",
          fr: "2222",
          ar: "2222",
        },
      },
      {
        type: "text",
        value: {
          en: "3333",
          fr: "3333",
          ar: "3333",
        },
      },
      {
        type: "text",
        value: {
          en: "4444",
          fr: "4444",
          ar: "4444",
        },
      },
    ],
    timer: 30,
  },
};

export const answer7Case2Mock = {
  id: "634575a2efc0cebdeb02a7b3",
  updated_round_index: 7,
  round: {
    answer_index: 3,
    answer_timer: 29,
    correct: false,
    correct_answer_index: 2,
  },
  opponent_round: {
    answer_index: 3,
    answer_timer: 25,
    correct: false,
  },
  current_round_index: 7,
  finished: false,
  skills: {
    lives: 4,
    items: {
      hide: {
        available: true,
      },
      skip: {
        available: true,
      },
      ask_genie: {
        available: true,
      },
    },
  },
};
