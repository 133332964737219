import { useState, useEffect, useRef } from "react";

export default function useComponentHighlight() {
  const [isComponentHighlight, setIsComponentHighlight] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.ChangeEvent<HTMLDivElement>) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentHighlight(true);
      setTimeout(() => {
        setIsComponentHighlight(false);
      }, 200);
    }
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      // @ts-ignore
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentHighlight, setIsComponentHighlight };
}
