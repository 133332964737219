import { ComponentProps } from "react";
import "./player.scss";

export interface PlayerProps extends ComponentProps<"div"> {
  playerType?: "primary" | "success" | "defeat";
  bottom?: Array<JSX.Element> | JSX.Element | string;
  left?: Array<JSX.Element> | JSX.Element | string;
  right?: Array<JSX.Element> | JSX.Element | string;
  avatar: string | undefined;
  winner?: boolean;
  name?: string;
  hasShadow?: boolean;
}

export function Player({
  playerType,
  bottom,
  left,
  right,
  avatar,
  winner,
  name,
  hasShadow = true,
  ...props
}: PlayerProps) {
  return (
    <div
      {...props}
      className={`player player-${playerType || "primary"}
      ${winner ? "winner" : ""}
      ${props.className ?? ""} ${hasShadow ? "has-shadow" : ""}`}
    >
      <div className="img" style={{ backgroundImage: `url(${avatar})` }} />
      {winner ? (
        <img className="crown" src="../img/icns/crown.svg" alt="" />
      ) : null}
      {left ? <div className="slot-left">{left}</div> : null}
      {right ? <div className="slot-right">{right}</div> : null}
      {bottom ? <div className="slot-bottom">{bottom}</div> : null}
      {name ? <p className="name">{name}</p> : null}
    </div>
  );
}

export default Player;
/**
 * <Player bottom={<SomeComponent>} />
 */
