import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import AccordionItem from "../../ui/accordion-item/accordion-item";
import "./faq-view.scss";
import { Faq } from "../../../shared/interfaces/general.types";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { motion, Variants } from "framer-motion";

const faqItemVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.1 * (custom + 1),
    },
  }),
};

export function FaqView() {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const tutorialData = {
    items: [
      {
        title: {
          en: "Veritatis natus aut quasi pariatur officiis commodi.",
          fr: "Necessitatibus consectetur ad aut est sit quos non.",
          ar: "",
        },
        description: {
          en: "THAN A MILE HIGH TO LEAVE THE COURT.' Everybody looked at them with one eye, How the Owl and the little golden key was lying under the sea,' the Gryphon went on. 'We had the best of educations--in.",
          fr: "Mock Turtle sighed deeply, and began, in a low, timid voice, 'If you please, sir--' The Rabbit Sends in a more subdued tone, and she thought it over a little scream of laughter. 'Oh, hush!' the.",
          ar: "",
        },
        order: 1,
      },
      {
        title: {
          en: "Sunt quis officia aspernatur recusandae rem sed.",
          fr: "Et commodi sed id sint quo ut itaque.",
          ar: "",
        },
        description: {
          en: "Alice, seriously, 'I'll have nothing more to do next, when suddenly a White Rabbit blew three blasts on the hearth and grinning from ear to ear. 'Please would you tell me, please, which way I want.",
          fr: "This time there were any tears. No, there were a Duck and a Dodo, a Lory and an old crab, HE was.' 'I never said I could not remember ever having seen such a wretched height to rest herself, and.",
          ar: "",
        },
        order: 2,
      },
      {
        title: {
          en: "Quia error soluta et corporis exercitationem ut.",
          fr: "Ut dolorem expedita rem perspiciatis cupiditate eaque recusandae tenetur.",
          ar: "",
        },
        description: {
          en: "I'll tell you what year it is?' 'Of course twinkling begins with a lobster as a boon, Was kindly permitted to pocket the spoon: While the Owl had the door opened inwards, and Alice's elbow was.",
          fr: "Alice, looking down at her feet in the world you fly, Like a tea-tray in the other. 'I beg your pardon,' said Alice very humbly: 'you had got to the Gryphon. 'The reason is,' said the Caterpillar.",
          ar: "",
        },
        order: 3,
      },
      {
        title: {
          en: "Modi accusantium reprehenderit et saepe architecto facere laborum.",
          fr: "Aliquam dolorum aut eum ut omnis voluptatem aut.",
          ar: "",
        },
        description: {
          en: "Alice heard it before,' said the White Rabbit returning, splendidly dressed, with a sudden leap out of the Lizard's slate-pencil, and the three gardeners instantly jumped up, and reduced the answer.",
          fr: "The Mouse only growled in reply. 'Please come back with the lobsters and the moment they saw her, they hurried back to yesterday, because I was a very deep well. Either the well was very likely.",
          ar: "",
        },
        order: 4,
      },
      {
        title: {
          en: "Sit quia assumenda accusamus laudantium velit.",
          fr: "Ex incidunt dignissimos nemo qui ut explicabo aut blanditiis.",
          ar: "",
        },
        description: {
          en: "The other guests had taken advantage of the way--' 'THAT generally takes some time,' interrupted the Hatter: 'it's very easy to know what to do, and in another moment, splash! she was quite.",
          fr: "You see, she came upon a little hot tea upon its nose. The Dormouse slowly opened his eyes. He looked at her feet as the Dormouse go on in a melancholy air, and, after glaring at her feet as the.",
          ar: "",
        },
        order: 5,
      },
      {
        title: {
          en: "Quo explicabo earum illo quia quia.",
          fr: "Quasi dolores corporis vero porro sint.",
          ar: "",
        },
        description: {
          en: "YOUR shoes done with?' said the Hatter, and here the conversation a little. ''Tis so,' said Alice. 'Come on, then!' roared the Queen, tossing her head was so small as this is May it won't be raving.",
          fr: "The hedgehog was engaged in a frightened tone. 'The Queen of Hearts, she made out that she wanted to send the hedgehog had unrolled itself, and was going to dive in among the branches, and every now.",
          ar: "",
        },
        order: 6,
      },
      {
        title: {
          en: "In quibusdam eveniet voluptatem molestiae rerum et.",
          fr: "Quaerat blanditiis rerum voluptas reiciendis eum.",
          ar: "",
        },
        description: {
          en: "For some minutes the whole cause, and condemn you to set them free, Exactly as we needn't try to find any. And yet I wish you wouldn't squeeze so.' said the Mock Turtle: 'crumbs would all come.",
          fr: "Caterpillar's making such a subject! Our family always HATED cats: nasty, low, vulgar things! Don't let me help to undo it!' 'I shall sit here,' the Footman continued in the distance, screaming with.",
          ar: "",
        },
        order: 7,
      },
      {
        title: {
          en: "Repellat dolorum fugiat unde.",
          fr: "Deserunt consequatur magnam fuga nostrum nemo accusamus totam.",
          ar: "",
        },
        description: {
          en: "Alice ventured to ask. 'Suppose we change the subject. 'Ten hours the first to break the silence. 'What day of the sort!' said Alice. 'Nothing WHATEVER?' persisted the King. Here one of them didn't.",
          fr: "VERY unpleasant state of mind, she turned the corner, but the Dodo managed it.) First it marked out a history of the trees had a head unless there was hardly room for her. 'Yes!' shouted Alice.",
          ar: "",
        },
        order: 8,
      },
      {
        title: {
          en: "Autem eos neque omnis nemo.",
          fr: "Et temporibus et neque odit architecto.",
          ar: "",
        },
        description: {
          en: "Queen, 'Really, my dear, YOU must cross-examine the next witness was the only difficulty was, that she had never been so much into the open air. 'IF I don't want to be?' it asked. 'Oh, I'm not the.",
          fr: "It means much the most confusing thing I ever was at in all directions, 'just like a wild beast, screamed 'Off with her arms folded, quietly smoking a long and a great crash, as if it had fallen.",
          ar: "",
        },
        order: 9,
      },
      {
        title: {
          en: "Vero qui autem qui corporis velit et explicabo.",
          fr: "Sapiente et impedit soluta enim qui nobis.",
          ar: "",
        },
        description: {
          en: "Gryphon. 'I mean, what makes them sour--and camomile that makes them sour--and camomile that makes them sour--and camomile that makes them sour--and camomile that makes you forget to talk. I can't.",
          fr: "I don't keep the same when I sleep is the reason so many lessons to learn! No, I've made up my mind about it; if I'm not Ada,' she said, without opening its eyes, for it was only too glad to find.",
          ar: "",
        },
        order: 10,
      },
      {
        title: {
          en: "Animi incidunt consectetur dolore non vero.",
          fr: "In libero nihil dignissimos tempore.",
          ar: "",
        },
        description: {
          en: "Alice a little anxiously. 'Yes,' said Alice, 'because I'm not myself, you see.' 'I don't know the meaning of half those long words, and, what's more, I don't want to see anything; then she looked up.",
          fr: "I THINK,' said Alice. 'Why, SHE,' said the Queen, and Alice, were in custody and under sentence of execution.' 'What for?' said the Caterpillar contemptuously. 'Who are YOU?' Which brought them back.",
          ar: "",
        },
        order: 11,
      },
      {
        title: {
          en: "Voluptas dolores ea velit qui quibusdam asperiores.",
          fr: "Dolorem porro sed numquam tenetur qui.",
          ar: "",
        },
        description: {
          en: "So they couldn't get them out again. That's all.' 'Thank you,' said the Hatter. 'You might just as if he wasn't one?' Alice asked. The Hatter was the first verse,' said the Gryphon: and it sat down.",
          fr: "William the Conqueror.' (For, with all her knowledge of history, Alice had been to her, And mentioned me to introduce some other subject of conversation. While she was now only ten inches high, and.",
          ar: "",
        },
        order: 12,
      },
      {
        title: {
          en: "Molestiae ullam minima ullam accusamus quia expedita.",
          fr: "Modi qui placeat esse a in est.",
          ar: "",
        },
        description: {
          en: "Owl, as a partner!' cried the Mock Turtle at last, with a lobster as a drawing of a muchness--did you ever see you again, you dear old thing!' said the Mock Turtle at last, and they repeated their.",
          fr: "An enormous puppy was looking up into a chrysalis--you will some day, you know--and then after that savage Queen: so she felt that it signifies much,' she said to herself. 'Shy, they seem to dry me.",
          ar: "",
        },
        order: 13,
      },
      {
        title: {
          en: "Omnis laudantium et quia veniam omnis necessitatibus.",
          fr: "Rerum sit hic eaque expedita quis.",
          ar: "",
        },
        description: {
          en: "THE KING AND QUEEN OF HEARTS. Alice was a different person then.' 'Explain all that,' said the Dormouse; 'VERY ill.' Alice tried to beat time when I breathe!' 'It IS a long time with great.",
          fr: "Indeed, she had brought herself down to nine inches high. CHAPTER VI. Pig and Pepper For a minute or two she stood still where she was, and waited. When the procession came opposite to Alice, very.",
          ar: "",
        },
        order: 14,
      },
      {
        title: {
          en: "Dolor cumque eveniet qui ullam pariatur nostrum.",
          fr: "Nobis sed molestiae fugiat.",
          ar: "",
        },
        description: {
          en: "Duchess! The Duchess! Oh my dear paws! Oh my dear Dinah! I wonder what I should be free of them were animals, and some 'unimportant.' Alice could not think of any that do,' Alice hastily replied.",
          fr: "PRECIOUS nose'; as an explanation; 'I've none of my own. I'm a hatter.' Here the other guinea-pig cheered, and was in the other. In the very middle of one! There ought to be seen: she found she.",
          ar: "",
        },
        order: 15,
      },
    ],
  };

  const renderFaqItems = (faqItems: Faq["items"]) => {
    return faqItems.map((item, index) => {
      return (
        <motion.div
          key={`tutorial-${item.order}`}
          variants={faqItemVariants}
          initial="hidden"
          animate="visible"
          custom={index}
        >
          <AccordionItem title={item.title[lang]}>
            {item.description[lang]}
          </AccordionItem>
        </motion.div>
      );
    });
  };

  return (
    <div className="accordion-container">
      <Background type="home">
        <NavBar hasAvatar />
        <div className="faq-content">
          <Title backURL="/">
            {checkTranslationKey(translations[lang]?.FAQ, "FAQ")}
          </Title>
          <ul className="accordion">
            {tutorialData ? renderFaqItems(tutorialData.items) : null}
          </ul>
        </div>
      </Background>
    </div>
  );
}

export default FaqView;
