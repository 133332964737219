import { useEffect } from "react";
import SoundManager from "../managers/sound-manager";
import useLocals from "./use-locals";

type UseSoundParams = keyof typeof SoundManager.ambientSounds;

const useAmbientSound = (props: UseSoundParams) => {
  const soundName = props;
  const { getValue } = useLocals();

  useEffect(() => {
    const sound = SoundManager.ambientSounds[soundName];
    sound.loop = true;

    // if sounds are disabled mute all audios
    for (const [, value] of Object.entries(SoundManager.vfxSounds)) {
      value.muted = !!getValue("soundsDisabled");
    }

    // autoplay ambient music if sounds are NOT disabled
    if (!getValue("soundsDisabled")) {
      sound.play();
    }

    return () => {
      sound.pause();
      sound.currentTime = 0;
    };
  }, []);
};

export default useAmbientSound;
