import "./prize-to-win.scss";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export function PrizeToWin() {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const grandPrize = {
    id: "634030553922dc23210422b5",
    name: {
      en: "Grand Prize",
      fr: "Grand Prize",
      ar: "Grand Prize",
    },
    description: {
      en: "1 x BMW 3 Series",
      fr: "1 x BMW 3 Series",
      ar: "1 x BMW 3 Series",
    },
    image: "/img/prize/grand.png",
    quantity: 1,
    position: 1,
    template_id: 1,
    created_at: "2022-10-07T13:57:41.000000Z",
    updated_at: "2022-10-07T13:57:41.000000Z",
  };

  return (
    <div id="prizes">
      {grandPrize && (
        <div className="prize">
          <div className="prize-image">
            <img src={grandPrize.image} alt="" />
          </div>
          {/* @ts-ignore */}
          <div className="prize-name">{grandPrize.name[lang]}</div>
        </div>
      )}
      <div className="prize-title">
        {checkTranslationKey(translations[lang]?.Prizes, "Prizes")}
        <div className="small-title">
          {checkTranslationKey(translations[lang]?.to_win, "to win!")}
        </div>
      </div>
      <div className="prize">
        <div className="prize-image">
          <img src="/img/prize/instant.png" alt="" />
        </div>
        <div className="prize-name">
          {checkTranslationKey(translations[lang]?.Strike_win, "Strike+Win")}
        </div>
      </div>
    </div>
  );
}
