import { Background } from "../../shared/ui/background";
import { UpstreamServiceBanner } from "../../shared/ui/upstream-service-banner";
import { Button } from "../../shared/ui/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";
import { Pagination, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./onboarding.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  selectDemoCase,
  selectLanguage,
  selectTranslations,
  updateDemoCase,
} from "../../shared/data-access/store/general/generalSlice";
import { useNavigate } from "react-router-dom";
import { checkTranslationKey } from "../../utils/translation";
import { Tutorial } from "../../faq/data-access/faq.service";
import Highlight from "../../shared/ui/highlight/highlight";
import useAmbientSound from "../../utils/hooks/use-ambient-sound";
import {
  selectMenuModal,
  setModal,
} from "../../shared/data-access/store/ui/uiSlice";
import Menu from "../../menu/feature/menu";

export function Onboarding() {
  const menuModal = useAppSelector(selectMenuModal);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [swiperController, setSwiperController] = useState<SwiperClass>();
  const [slideState, setSlideState] = useState<{
    slidesCount: number;
    activeSlide: number;
  }>({ slidesCount: 0, activeSlide: 0 });
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const demoCase = useAppSelector(selectDemoCase);
  useAmbientSound("tutorial");

  const onboardingData = {
    items: [
      {
        id: "630ce0c8eea00406410879a2",
        title: {
          en: "Test your knowledge vs. your opponent’s, gather points and increase your chances to win!",
          fr: "Test your knowledge vs. your opponent’s, gather points and increase your chances to win!",
          ar: "Test your knowledge vs. your opponent’s, gather points and increase your chances to win!",
        },
        description: {
          en: "description",
          fr: "description",
          ar: "description",
        },
        image: "/img/onboarding/question1.png",
        order: 1,
        updated_at: "2022-10-07T13:57:41.774000Z",
        created_at: "2022-10-07T13:57:41.774000Z",
      },
      {
        id: "630ce0e0a7177b7796089b26",
        title: {
          en: "Three magic helps are here for you to answer the questions",
          fr: "Three magic helps are here for you to answer the questions",
          ar: "Three magic helps are here for you to answer the questions",
        },
        description: {
          en: "description",
          fr: "description",
          ar: "description",
        },
        image: "/img/onboarding/question2.png",
        order: 2,
        updated_at: "2022-10-07T13:57:41.775000Z",
        created_at: "2022-10-07T13:57:41.775000Z",
      },
      {
        id: "630ce0eff6e5118b7d079483",
        title: {
          en: "Level up and Get extra lives! Special badges are also waiting you!",
          fr: "Level up and Get extra lives! Special badges are also waiting you!",
          ar: "Level up and Get extra lives! Special badges are also waiting you!",
        },
        description: {
          en: "description",
          fr: "description",
          ar: "description",
        },
        image: "/img/onboarding/question3.png",
        order: 3,
        updated_at: "2022-10-07T13:57:41.777000Z",
        created_at: "2022-10-07T13:57:41.777000Z",
      },
      {
        id: "630ce0fdeea00406410879a5",
        title: {
          en: "Play now and be closer to the Grand Prize!  Make a strike by answering  10 questions and win extra gifts!",
          fr: "Play now and be closer to the Grand Prize!  Make a strike by answering  10 questions and win extra gifts!",
          ar: "Play now and be closer to the Grand Prize!  Make a strike by answering  10 questions and win extra gifts!",
        },
        description: {
          en: "description",
          fr: "description",
          ar: "description",
        },
        image: "/img/onboarding/question4.png",
        order: 4,
        updated_at: "2022-10-07T13:57:41.778000Z",
        created_at: "2022-10-07T13:57:41.778000Z",
      },
      {
        id: "630ce10ea7177b7796089b29",
        title: {
          en: "Get extra credits to play more games and showcase your knowledge!",
          fr: "Get extra credits to play more games and showcase your knowledge!",
          ar: "Get extra credits to play more games and showcase your knowledge!",
        },
        description: {
          en: "description",
          fr: "description",
          ar: "description",
        },
        image: "/img/onboarding/question5.png",
        order: 5,
        updated_at: "2022-10-07T13:57:41.779000Z",
        created_at: "2022-10-07T13:57:41.779000Z",
      },
    ],
  };

  const lastSlide =
    (slideState?.activeSlide || 0) + 1 === slideState?.slidesCount;

  const handleNavigate = () => {
    if (demoCase && demoCase.case === 1) {
      dispatch(updateDemoCase({ case: demoCase.case, step: 2 }));
      navigate("/home");
    } else if (demoCase && demoCase.case === 3) {
      dispatch(setModal({ modal: "menu", value: true }));
    }
  };

  useEffect(() => {
    if (!swiperController) {
      return;
    }
    function onSlideChange() {
      setSlideState({
        slidesCount: swiperController?.slides?.length || 0,
        activeSlide: swiperController?.activeIndex || 0,
      });
    }
    swiperController.on("slideChange", onSlideChange);
    onSlideChange();
    return () => {
      swiperController.off("slideChange", onSlideChange);
    };
  }, [swiperController]);

  const renderOnboardingItems = (onboardingItems: Tutorial["items"]) => {
    return onboardingItems.map((onboardingItem) => {
      return (
        <SwiperSlide key={`onboarding-item-${onboardingItem.order}`}>
          <div className="slide-container">
            <div className="image-container">
              <span className="image-background"></span>
              <img
                src={onboardingItem.image}
                alt={checkTranslationKey(
                  translations[lang]?.Onboarding,
                  "Onboarding"
                )}
              />
            </div>
            <div className="text-container">
              <h3>{onboardingItem.title[lang]}</h3>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div id="onboarding">
      <Background type="onboarding">
        <div className={`modal-menu ${menuModal ? "open" : ""}`}>
          <Menu />
        </div>
        <div className="header-transparent">
          <img
            src="/img/batteup-logo.png"
            alt={checkTranslationKey(
              translations[lang]?.BattleUp_Logo,
              "BattleUp Logo"
            )}
            className="logo"
          />
        </div>

        <div className="onboarding-container">
          <div className="slides">
            <Swiper
              pagination={{ clickable: true }}
              fadeEffect={{
                crossFade: true,
              }}
              modules={[Pagination, EffectFade]}
              effect={"fade"}
              slidesPerView={1}
              onSwiper={(swiper) => setSwiperController(swiper)}
            >
              {onboardingData && onboardingData.items
                ? renderOnboardingItems(onboardingData.items)
                : null}
            </Swiper>
          </div>

          <div className="buttons">
            <Highlight>
              <Button
                buttonType={lastSlide ? "success" : "primary"}
                onClick={() =>
                  lastSlide ? handleNavigate() : swiperController?.slideNext()
                }
              >
                {lastSlide
                  ? checkTranslationKey(
                      translations[lang]?.Play_now,
                      "Play now!"
                    )
                  : checkTranslationKey(translations[lang]?.Next, "Next!")}
              </Button>
            </Highlight>
            {!lastSlide ? (
              <Button buttonType="transparent" onClick={() => false}>
                {checkTranslationKey(
                  translations[lang]?.Skip_and_play,
                  "Skip and play"
                )}
              </Button>
            ) : null}
          </div>
        </div>
      </Background>
      <div className="service-banner">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default Onboarding;
