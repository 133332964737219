import { Background, BackgroundType } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import "./category-view.scss";
import { PrizeToWin } from "../../../shared/ui/prize-to-win";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
// import { selectGame } from "../../../shared/data-access/store/game/gameSlices";
import { useNavigate } from "react-router-dom";
import {
  selectDemoCase,
  selectLanguage,
  updateDemoCase,
} from "../../../shared/data-access/store/general/generalSlice";
import { animated, useSpring } from "react-spring";
import { useGesture } from "@use-gesture/react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useGameEvents } from "../../../shared/data-access/store/game/hooks/use-game-events";
import { Categories } from "../../../shared/data-access/store/game/types/socket-events/category";
import SoundManager from "../../../utils/managers/sound-manager";
import {
  selectUser,
  updateUser,
} from "../../../auth/data-access/store/authSlice";
import { User } from "../../../shared/interfaces/auth.types";

const peelTextVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const peelHandVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  pulse: {
    opacity: 1,
    scale: [1, 1.2, 1],
    transition: {
      delay: 0.3,
      scale: {
        duration: 1,
        repeat: Infinity,
        ease: "easeOut",
      },
    },
  },
};

const navbarVariants: Variants = {
  hidden: {
    y: "-100%",
    transition: {
      duration: 0.3,
    },
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const circlePeelVariants: Variants = {
  hidden: (custom) => ({
    x: custom === 1 ? "-200%" : "200%",
    transition: {
      duration: 0.3,
    },
  }),
  visible: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const circlePeelMainVariants: Variants = {
  hidden: {
    y: "200%",
    transition: {
      duration: 0.3,
    },
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const CIRCLE_HEIGHT = 280;

export const categoryMock = {
  id: "6346c7c127e32a14ca04f126",
  current_round_index: 0,
  category: {
    id: "634030553922dc2321042296",
    code: "general_knowledge",
    name: {
      en: "General knowledge",
      fr: "General knowledge",
      ar: "General knowledge",
    },
    description: {
      en: "General knowledge",
      fr: "General knowledge",
      ar: "General knowledge",
    },
    icon: "https://api.battleup.dev1.beecoded.ro/storage/question_categories/generalknowledge.png",
    color: "#56D598",
    gradient_background: "linear-gradient(180deg, #56D598 0%, #27AA6B 100%)",
    gradient_circle:
      "linear-gradient(0, #0D864C 10.35%, #56D598 45.59%, #A8F8D1 73.09%, #56D598 84.21%)",
  },
};

const categoryMockSports = {
  id: "6349778d4a7b6b00c60fd459",
  current_round_index: 0,
  category: {
    id: "6340306188dcf7549b089088",
    code: "sports",
    name: {
      en: "Sports",
      fr: "Sports",
      ar: "Sports",
    },
    description: {
      en: "Sports",
      fr: "Sports",
      ar: "Sports",
    },
    icon: "https://api.battleup.stage1.beecoded.ro/storage/question_categories/sports.png",
    color: "#FF5383",
    gradient_background: "linear-gradient(180deg, #FF5383 0%, #A43453 100%)",
    gradient_circle:
      "linear-gradient(0, #9F173D 10.35%, #FF5383 45.59%, #FFB1C7 73.09%, #FF5383 84.21%)",
  },
};

const categoryMockGeography = {
  id: "634d31e9dd1809a7570ab8e3",
  current_round_index: 0,
  category: {
    id: "6340306188dcf7549b089087",
    code: "geography",
    name: {
      en: "Geography",
      fr: "Geography",
      ar: "Geography",
    },
    description: {
      en: "Geography",
      fr: "Geography",
      ar: "Geography",
    },
    icon: "https://api.battleup.stage1.beecoded.ro/storage/question_categories/geography.png",
    color: "#A7A3FE",
    gradient_background: "linear-gradient(180deg, #A7A3FE 0%, #726EC7 100%)",
    gradient_circle:
      "linear-gradient(0, #464294 10.35%, #A7A3FE 45.59%, #D8D7FF 73.09%, #A7A3FE 84.21%)",
  },
};

export function CategoryView() {
  const demoCase = useAppSelector(selectDemoCase);
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lang = useAppSelector(selectLanguage);
  const { resetState } = useGameEvents("get_category");
  const peelCircleRef = useRef<HTMLDivElement>(null);
  const circleDragRef = useRef<HTMLDivElement>(null);
  const [finish, setFinish] = useState(false);
  const [showPeelText, setShowPeelText] = useState(true);
  const [showExitAnimations, setShowExitAnimations] = useState(false);
  const [backgroundPeelVariants, setBackgroundPeelVariants] =
    useState<Variants>({
      [BackgroundType.defaultGame]: {
        background:
          "linear-gradient(0, #0D864C 10.35%, #56D598 45.59%, #A8F8D1 73.09%, #56D598 84.21%)",
      },
    });

  const [styles, api] = useSpring(() => ({
    from: {
      height: 10,
      lastPeelAmount: 10,
      peelAmount: 10,
      rotateValue: 45,
      rotatePeelValue: 0,
    },
  }));

  // const backgroundValues = Object.values(BackgroundType);
  let categoryData = categoryMock;

  if (demoCase && demoCase.case === 2 && demoCase.step === 12) {
    categoryData = categoryMockSports;
  } else if (demoCase && demoCase.case === 2 && demoCase.step === 21) {
    categoryData = categoryMockGeography;
  }

  useEffect(() => {
    if (categoryData) {
      setBackgroundPeelVariants({
        ...backgroundPeelVariants,
        categoryCircleGradient: {
          background: categoryData.category.gradient_circle,
        },
      });
    }
  }, [categoryData]);

  useEffect(() => {
    if (finish) {
      SoundManager.vfxSounds.swipe.play();

      setTimeout(() => {
        setShowExitAnimations(true);
      }, 2500);

      let canUseCredits = false;

      if (user) {
        if (
          user.eligibility.credits.subscription +
            user.eligibility.credits.purchased >
          1
        ) {
          canUseCredits = true;
        }
        dispatch(
          updateUser({
            ...user,
            eligibility: {
              ...user.eligibility,
              credits: {
                ...user.eligibility.credits,
                subscription: canUseCredits
                  ? user.eligibility.credits.subscription - 1
                  : user.eligibility.credits.subscription,
              },
            },
            character: {
              ...user.character,
              skills: {
                ...user.character.skills,
                lives:
                  user.character.skills.lives > 1
                    ? user.character.skills.lives - 1
                    : user.character.skills.lives,
              },
            },
          } as User)
        );
      }

      setTimeout(() => {
        if (demoCase) {
          if (demoCase.case === 1 && demoCase.step === 5) {
            dispatch(updateDemoCase({ case: demoCase.case, step: 6 }));
          } else if (demoCase.case === 2 && demoCase.step === 4) {
            dispatch(updateDemoCase({ case: demoCase.case, step: 5 }));
          } else if (demoCase.case === 2 && demoCase.step === 12) {
            dispatch(updateDemoCase({ case: demoCase.case, step: 13 }));
          } else if (demoCase.case === 2 && demoCase.step === 21) {
            dispatch(updateDemoCase({ case: demoCase.case, step: 22 }));
          }
          navigate("/gameplay/classic");
        }
        resetState();
      }, 3000);
    }
  }, [finish]);

  function mapRange(
    number: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number
  ) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  useGesture(
    {
      onDragStart: () => {
        api.set({ lastPeelAmount: styles.peelAmount.get() });
      },
      onDrag: ({ down, movement: [mx, my] }) => {
        const dist = (my > 0 ? 1 : -1) * Math.hypot(mx, my);
        const peelDiff = ((dist / CIRCLE_HEIGHT) * 100) / 2;
        const newPeel = styles.lastPeelAmount.get() + peelDiff;
        const maxPeelValue = 70;
        if (
          Math.max(newPeel, 10) === newPeel &&
          newPeel < maxPeelValue &&
          !finish
        ) {
          if (newPeel > maxPeelValue - 2) {
            setFinish(true);
          }
          if (showPeelText) {
            setShowPeelText(false);
          }
          api.start({
            peelAmount: newPeel,
            rotatePeelValue: mapRange(newPeel, 10, 70, 0, -30),
            immediate: down,
          });
        }
      },
    },
    { target: circleDragRef, eventOptions: { passive: false } }
  );

  return (
    <div id="category">
      <Background
        type={
          categoryData && categoryData.category.code && finish
            ? (categoryData.category.code as Categories)
            : "defaultGame"
        }
        category={
          categoryData && categoryData.category.code && finish
            ? categoryData?.category.gradient_background
            : ""
        }
      >
        <div className="category-content">
          <motion.div
            variants={navbarVariants}
            initial={false}
            animate={showExitAnimations ? "hidden" : ""}
          >
            <NavBar hasStats hasAvatar></NavBar>

            <motion.div
              className="prizes"
              variants={navbarVariants}
              initial="hidden"
              animate="visible"
            >
              <PrizeToWin></PrizeToWin>
            </motion.div>
          </motion.div>

          <div id="peel">
            <motion.div
              className="peel-circle peel-circle-orange"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-purple"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
              custom={1}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-pink"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-indigo"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
              custom={1}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-indigo-2"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-purple-2"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-blue"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
              custom={1}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-aqua"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
              custom={1}
            ></motion.div>
            <motion.div
              className="peel-circle peel-circle-orange-dark"
              variants={circlePeelVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
            ></motion.div>
            <motion.div
              className="peel-circle-wrapper"
              variants={circlePeelMainVariants}
              initial={false}
              animate={showExitAnimations ? "hidden" : ""}
            >
              <AnimatePresence>
                {showPeelText && (
                  <motion.img
                    src="/img/hand.png"
                    alt=""
                    className="hand-peel"
                    variants={peelHandVariants}
                    initial="visible"
                    animate="pulse"
                    exit="hidden"
                  />
                )}
              </AnimatePresence>
              <animated.div
                className="peel-circle-special"
                ref={peelCircleRef}
                style={{
                  transform: styles.rotateValue.to(
                    (value) => `rotate(${value}deg)`
                  ),
                }}
              >
                <div className="circle-content-wrapper">
                  <div className="circle-content">
                    <img
                      src={categoryData?.category?.icon}
                      alt=""
                      className="icon-category"
                    />
                    <h3>
                      {
                        // @ts-ignore
                        categoryData?.category?.name[lang]
                      }
                    </h3>
                    <div className="dot-flashing"></div>
                  </div>
                </div>
                <animated.div
                  className="circle-inner"
                  style={{
                    transform: styles.rotatePeelValue.to(
                      (value) => `rotate(${value}deg)`
                    ),
                  }}
                >
                  <animated.div
                    className="circle-bg-wrapper"
                    style={{
                      height: styles.peelAmount.to(
                        (value) => `${100 - value}%`
                      ),
                    }}
                  >
                    <div className="circle-bg"></div>
                  </animated.div>
                  <animated.div
                    className="circle-top"
                    style={{
                      top: styles.peelAmount.to((value) => `${value}%`),
                      height: styles.peelAmount.to((value) => `${value}%`),
                    }}
                    ref={circleDragRef}
                  >
                    <motion.div
                      className="circle"
                      style={{
                        height: CIRCLE_HEIGHT,
                        width: CIRCLE_HEIGHT,
                      }}
                      initial="hidden"
                      animate={
                        // @ts-ignore
                        categoryData?.category?.code && finish
                          ? "categoryCircleGradient"
                          : "defaultGame"
                      }
                      variants={backgroundPeelVariants}
                    ></motion.div>
                  </animated.div>
                </animated.div>

                <AnimatePresence>
                  {showPeelText && (
                    <motion.div
                      className="peel-to-play"
                      variants={peelTextVariants}
                      initial="visible"
                      animate="visible"
                      exit="hidden"
                    >
                      <h3>Peel to play!</h3>
                    </motion.div>
                  )}
                </AnimatePresence>
              </animated.div>
            </motion.div>
          </div>
        </div>
      </Background>
    </div>
  );
}

export default CategoryView;
